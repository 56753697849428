import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import * as Ct from "ldlj"
import { opacityColors } from "../styles/design.config"
import { AccountLine } from "../store/ducks/merchants.ducks"

export const StandardLedgerOutput = ({
  accountLine,
}: {
  accountLine: AccountLine
}) => {
  const intl = useIntl()

  return (
    <WrapperLedger>
      <Header>
        <Ct.Text
          text={intl.formatMessage({
            id: "ged.modal.standard-ledger.compteNum",
          })}
          textStyle={{
            fontWeight: 700,
          }}
        />
        <Ct.Text
          text={intl.formatMessage({
            id: "ged.modal.standard-ledger.compteLib",
          })}
          textStyle={{
            fontWeight: 700,
          }}
        />
        <Ct.Text
          text={intl.formatMessage({
            id: "ged.modal.standard-ledger.compteAuxNum",
          })}
          textStyle={{
            fontWeight: 700,
          }}
        />
        <Ct.Text
          text={intl.formatMessage({
            id: "ged.modal.standard-ledger.compteAuxLib",
          })}
          textStyle={{
            fontWeight: 700,
          }}
        />
      </Header>
      <Ct.Spacer height={3} />
      <CardBody id={"cardMerchantStandardLedger"}>
        <Ct.Text text={accountLine.number} />
        <Ct.Text text={accountLine.details} />
        <Ct.Text text={accountLine.auxiliaryDetails} />
        <Ct.Text text={accountLine.auxiliaryNumber} />
      </CardBody>
      <Ct.Spacer height={3} />
    </WrapperLedger>
  )
}

const WrapperLedger = styled.div`
  border-radius: 1.25rem;
  width: 100%;
  border: 1px solid ${opacityColors.gray};
`

const Header = styled.header`
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  width: initial;
  background-color: ${opacityColors.grayTransparent};
  padding: 2rem 3rem;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;

  && {
    text-align: center;
    grid-area: 1 / 1 / 2 / 5;
  }
`

const CardBody = styled.div`
  padding: 0 3rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;

  && {
    text-align: center;
    grid-area: 1 / 1 / 2 / 5;
  }
`
