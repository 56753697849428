import * as Ct from "ldlj"
import { useEffect, useState, VFC } from "react"
import { useDispatch } from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components/macro"
import { ReactComponent as Search } from "../../../../assets/search.svg"
import { ReactComponent as Reload } from "../../../../assets/reload.svg"
import { FiscalYearPicker } from "../../../../components/FiscalYearPicker"
import {
  AcdImportInformations,
  createWritingArchiveDownloadEvent,
  createWritingArchiveThunk,
  downloadWritingThunk,
  getAllWritingArchivesThunk,
  getWritingArchiveDownloadHistory,
  getWritingArchivesThunk,
  InqomImportInformations,
  ValidateWritingReset,
  Writing,
} from "../../../../store/ducks/writings.ducks"
import { useRNBSelector } from "../../../../store/rootReducer"
import { gradients } from "../../../../styles/design.config"
import { getIdFromParams } from "../../../../utils/company"

import { useIntl } from "react-intl"
import { Alert } from "../../../../components/Commons/Alert"
import { Text } from "../../../../components/Commons/Text"
import {
  ChevronProps,
  SortToReturn,
  Table,
} from "../../../../components/Commons/Table"
import { InputMaskModal } from "../../../../components/inputMask/InputMaskModal"
import { WritingArchiveDownloadHistoryModal } from "../../../../components/WritingArchiveDownloadHistoryModal"
import { ConfirmVerificationModalModal } from "../../../../components/writings/ConfirmVerificationModal"
import { ValidateWritingModal } from "../../../../components/writings/ValidateWritingModal"
import { RegenerateArchiveModal } from "../../../../components/writings/RegenerateArchiveModal"
import { GetCompanyThunk } from "../../../../store/ducks/companies.ducks"
import {
  sortWritingsByArchiveId,
  sortWritingsByDate,
  sortWritingsByEmail,
  sortWritingsByProgress,
  showFiscalYear,
  setProgressBarColor,
  setSubtitle,
} from "../../../../utils/writings"
import { ProgressBar } from "../../../../components/Commons/ProgressBar"
import { Button } from "../../../../components/Commons/Button"

export type ArchiveStatus =
  | "PENDING"
  | "WRITING_READY"
  | "READY_FOR_ARCHIVE"
  | "CREATED"
  | "GENERATED"

export interface WritingsLineToDisplay {
  status: ArchiveStatus
  documentsNumber: number
  batchNumber?: number
  deactivatedCount?: number
  processedCount?: number
  progress: number
  downloadUser?: string
  downloadDate?: string
  archiveId?: number
  accountingSoftwareReference?: string
  accountSoftware?: string
  importStatus?: null | "in_progress" | "imported" | "import_error"
  importDate?: null | string
  importedCoalaPayloadsNumber?: number
  importErrorCoalaPayloadsNumber?: number
  inProgressCoalaPayloadsNumber?: number
  totalCoalaPayloadsNumber?: number
  inqomImportInformations?: InqomImportInformations
  acdImportInformations?: AcdImportInformations
}

export const CompanyWritingsDisplay: VFC = () => {
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useRNBSelector((state) => state.user)
  if (user.typology === "customer") navigate("/Unauthorized")
  const userId = useRNBSelector((state) => state.user.id)

  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId]
  )
  const selectedFiscalYearId = useRNBSelector(
    (state) => state.fiscalYears.selectedFiscalYearId || 0
  )
  const {
    accountSoftware,
    isConnectedToApi,
    companyAccountingSoftware,
    accountingSoftwareReference,
  } = useRNBSelector((state) => ({
    accountSoftware:
      state.companies.companies[selectedCompanyId]?.accounting_software,
    isConnectedToApi:
      state.companies.companies[selectedCompanyId]?.is_connected_to_api,
    companyAccountingSoftware:
      state.companies.companies[selectedCompanyId]?.accounting_software,
    accountingSoftwareReference:
      state.companies.companies[selectedCompanyId]
        ?.accounting_software_reference,
  }))
  const companysFY = useRNBSelector(
    (state) => state.fiscalYears.fiscalYearsByCompanyId[selectedCompanyId]
  )
  const archives = useRNBSelector(
    (state) => state.writings.data[selectedFiscalYearId]
  )
  const writingValidationStatus = useRNBSelector(
    (state) => state.writings.writingValidationStatus
  )
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)

  const [displayConfirmVerificationModal, setDisplayConfirmVerificationModal] =
    useState(false)
  const [displayInputMaskModal, setDisplayInputMaskModal] = useState(false)
  const [displayValidationWritingModal, setDisplayValidationWritingModal] =
    useState(false)
  const [initialLoad, setInitialLoad] = useState(false)
  const [dataToDisplay, setDataToDisplay] = useState<WritingsLineToDisplay[]>(
    []
  )
  const [search, setSearch] = useState<string>("")
  const [sortedDataToDisplay, setSortedDataToDisplay] =
    useState<WritingsLineToDisplay[]>(dataToDisplay)
  const [showModalRegenerate, setShowModalRegenrate] = useState<{
    show: boolean
    id: number | null
  }>({ show: false, id: null })
  const [downloadHistoryModalState, setDownloadHistoryModalState] = useState<{
    isDisplayed: boolean
    archiveId: number
  }>({
    isDisplayed: false,
    archiveId: 0,
  })
  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)
  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })
  const [selectedSendToApi, setSelectedSendToApi] = useState<boolean>(false)

  const selectedFiscalYear = companysFY
    ? companysFY.find((f) => f.id === selectedFiscalYearId)
    : null

  const callGetAllWritingArchives = () => {
    dispatch(
      getAllWritingArchivesThunk({
        companyId: selectedCompanyId,
        fiscalYearId: selectedFiscalYearId,
      })
    )
    setInitialLoad(false)
  }

  const rowTooltip = (row: WritingsLineToDisplay) => {
    if (row.status === "PENDING") {
      return {
        "data-tip": intl.formatMessage({ id: "writings.tooltip.pending" }),
      }
    }
    return {}
  }

  const rowBackgroundColors = sortedDataToDisplay.map(
    (row: WritingsLineToDisplay) => {
      if (row.status === "PENDING") {
        return "mist"
      }
      return "white"
    }
  )

  const columns: Ct.TableBuilder<WritingsLineToDisplay>[] = [
    {
      headerText: "company-writings.header.state",
      content: (line: WritingsLineToDisplay) => {
        const link = ["PENDING", "WRITING_READY", "READY_FOR_ARCHIVE"].includes(
          line.status
        )
          ? `${selectedFiscalYearId}?archive_status=${line.status.toLowerCase()}&company_id=${selectedCompanyId}`
          : `${selectedFiscalYearId}?archive_status=${line.status.toLowerCase()}&company_id=${selectedCompanyId}&archive_id=${
              line.archiveId
            }`

        return (
          <Cell>
            <div>
              <div>
                <div>
                  <b>
                    <Text
                      text={intl.formatMessage({
                        id: `writings.set-status-title.${line.status.toLowerCase()}`,
                      })}
                      textStyle={{
                        fontSize: 1.75,
                        fontWeight: 600,
                        color: "black",
                      }}
                    />
                  </b>
                </div>
                <div>
                  <b>{showFiscalYear(line.status, selectedFiscalYear)}</b>
                </div>
              </div>
              <Link to={link}>
                <StyledU>
                  {setSubtitle(
                    line.status,
                    line.documentsNumber,
                    line?.batchNumber || 0
                  )}
                </StyledU>
              </Link>
            </div>
          </Cell>
        )
      },
    },
    {
      headerText: "company-writings.header.progress",
      content: (line: WritingsLineToDisplay) => (
        <div>
          <Cell>
            <ProgressBarWrapper>
              <ProgressBar
                color={setProgressBarColor(line.status)}
                progress={line.progress}
                height={2}
                withText={true}
              />
            </ProgressBarWrapper>
          </Cell>
        </div>
      ),
    },
    {
      headerText: "company-writings.header.downloaded",
      content: (line: WritingsLineToDisplay) => (
        <Cell>
          <div>
            {line.downloadUser ? (
              <>
                {line.downloadUser}
                <div>
                  <StyledUButton
                    onClick={() => {
                      dispatch(
                        getWritingArchiveDownloadHistory(Number(line.archiveId))
                      )
                      setDownloadHistoryModalState({
                        isDisplayed: true,
                        archiveId: Number(line.archiveId),
                      })
                    }}
                  >
                    {intl.formatMessage({
                      id: "writings.download.history.check",
                    })}
                  </StyledUButton>
                </div>
              </>
            ) : (
              "-"
            )}
          </div>
        </Cell>
      ),
    },
    {
      headerText: "company-writings.header.date",
      content: (line: WritingsLineToDisplay) => (
        <Cell>{line.downloadDate ? line.downloadDate : "-"}</Cell>
      ),
    },
    {
      headerText: "company-writings.header.writing",
      content: (line: WritingsLineToDisplay) => {
        const wasDownloaded = Boolean(line.downloadUser)

        return (
          <ImportApiCell>
            <Cell>
              {(archives?.generatedStatus === "loading" &&
                line.status === "READY_FOR_ARCHIVE") ||
              line.status === "CREATED" ? (
                <StyledLoader>
                  <Ct.SpinningLoader />
                </StyledLoader>
              ) : (
                <>
                  <Button
                    colorType={
                      wasDownloaded || line.status === "WRITING_READY"
                        ? "Tertiary"
                        : "Primary"
                    }
                    colorScheme={
                      line.status === "WRITING_READY"
                        ? {
                            background: "purple",
                            color: "white",
                            border: "purple",
                          }
                        : wasDownloaded
                        ? {
                            background: "mist",
                            color: "cornflower",
                            border: "mist",
                          }
                        : undefined
                    }
                    height={4}
                    width={28}
                    disabled={line.status === "PENDING"}
                    label={intl.formatMessage(
                      {
                        id: `writings.set-action-title.${line.status.toLowerCase()}`,
                      },
                      { archiveId: line.archiveId }
                    )}
                    onClick={() => {
                      if (line.status === "WRITING_READY") {
                        setDisplayValidationWritingModal(true)
                      }
                      if (
                        line.status === "READY_FOR_ARCHIVE" &&
                        selectedFiscalYearId &&
                        selectedCompanyId
                      ) {
                        setDisplayConfirmVerificationModal(true)
                      } else if (
                        line.status === "GENERATED" &&
                        line.archiveId &&
                        selectedCompanyId
                      ) {
                        dispatch(
                          createWritingArchiveDownloadEvent(
                            line.archiveId,
                            userId
                          )
                        )
                        dispatch(
                          downloadWritingThunk(
                            line.archiveId,
                            selectedCompanyId,
                            line.accountingSoftwareReference,
                            line.accountSoftware
                          )
                        )
                      }
                    }}
                  />
                </>
              )}
            </Cell>
            {["my_unisoft", "coala", "inqom", "acd"].includes(
              String(companyAccountingSoftware)
            ) &&
              line.status === "GENERATED" && (
                <ImportTextWrapper>
                  {line.importDate &&
                    line.importStatus !== null &&
                    companyAccountingSoftware === "coala" && (
                      <Ct.Text
                        text={
                          intl.formatMessage(
                            {
                              id: `company-writings.import_api.${line.importStatus}`,
                            },
                            {
                              date: line.importDate,
                            }
                          ) +
                          intl.formatMessage(
                            { id: `company-writings.import_number` },
                            {
                              total: `${line.totalCoalaPayloadsNumber}`,
                              importedNumber: `${line.importedCoalaPayloadsNumber}`,
                            }
                          ) +
                          (line.deactivatedCount
                            ? ",  " +
                              intl.formatMessage({
                                id: `company-writings.deactivated_present`,
                              })
                            : "")
                        }
                        textStyle={{
                          fontSize: 1.75,
                          fontWeight: 400,
                          fontStyle: "italic",
                        }}
                      />
                    )}
                  {line.importDate &&
                    line.importStatus !== null &&
                    companyAccountingSoftware === "inqom" && (
                      <Ct.Text
                        text={
                          intl.formatMessage(
                            {
                              id: `company-writings.import_api.${line.importStatus}`,
                            },
                            {
                              date: line.importDate,
                            }
                          ) +
                          intl.formatMessage(
                            { id: `company-writings.inqom.import_number` },
                            {
                              total: `${line.inqomImportInformations?.total}`,
                              importedNumber: `${line.inqomImportInformations?.imported_number}`,
                            }
                          ) +
                          (line.deactivatedCount
                            ? ",  " +
                              intl.formatMessage({
                                id: `company-writings.deactivated_present`,
                              })
                            : "")
                        }
                        textStyle={{
                          fontSize: 1.75,
                          fontWeight: 400,
                          fontStyle: "italic",
                        }}
                      />
                    )}
                  {line.importDate &&
                    line.importStatus !== null &&
                    companyAccountingSoftware === "acd" && (
                      <Ct.Text
                        text={
                          intl.formatMessage(
                            {
                              id: `company-writings.import_api.${line.importStatus}`,
                            },
                            {
                              date: line.importDate,
                            }
                          ) +
                          intl.formatMessage(
                            { id: `company-writings.acd.import_number` },
                            {
                              total: `${line.acdImportInformations?.total}`,
                              importedNumber: `${line.acdImportInformations?.imported_number}`,
                            }
                          ) +
                          (line.deactivatedCount
                            ? ",  " +
                              intl.formatMessage({
                                id: `company-writings.deactivated_present`,
                              })
                            : "")
                        }
                        textStyle={{
                          fontSize: 1.75,
                          fontWeight: 400,
                          fontStyle: "italic",
                        }}
                      />
                    )}
                  {line.importDate &&
                    line.importStatus !== null &&
                    companyAccountingSoftware === "my_unisoft" && (
                      <Ct.Text
                        text={
                          intl.formatMessage(
                            {
                              id: `company-writings.import_api.${line.importStatus}`,
                            },
                            {
                              date: line.importDate,
                            }
                          ) +
                          (line.deactivatedCount
                            ? ",  " +
                              intl.formatMessage({
                                id: `company-writings.deactivated_present`,
                              })
                            : "")
                        }
                        textStyle={{
                          fontSize: 1.75,
                          fontWeight: 400,
                          fontStyle: "italic",
                        }}
                      />
                    )}
                  {!line.importDate && !line.importStatus && (
                    <Ct.Text
                      text={
                        line.deactivatedCount && !line.processedCount
                          ? intl.formatMessage({
                              id: `company-writings.no_import`,
                            }) +
                            intl.formatMessage({
                              id: `company-writings.only_deactivated`,
                            })
                          : intl.formatMessage({
                              id: `company-writings.no_import`,
                            })
                      }
                      textStyle={{
                        fontSize: 1.75,
                        fontWeight: 400,
                        fontStyle: "italic",
                        textAlign: "center",
                      }}
                    />
                  )}
                </ImportTextWrapper>
              )}
            {line.status === "GENERATED" && (
              <Regenerate
                onClick={() => {
                  if (line.archiveId) {
                    setShowModalRegenrate({ show: true, id: line.archiveId })
                  }
                }}
              >
                <StyledReload />
                <Ct.Spacer width={1} />
                <Ct.Text
                  text={intl.formatMessage({
                    id: `writing-archives.regenerate`,
                  })}
                  textStyle={{
                    fontSize: 1.75,
                    fontWeight: 600,
                    underline: true,
                  }}
                />
              </Regenerate>
            )}
          </ImportApiCell>
        )
      },
    },
  ]

  enum SortOptionsValues {
    "_",
    "progress",
    "download_by",
    "date",
    "archiveId",
  }

  const sorter = (asc: boolean) => (option: SortOptionsValues) => {
    if (option === SortOptionsValues?.progress) {
      setSortedDataToDisplay(sortWritingsByProgress(sortedDataToDisplay, asc))
    } else if (option === SortOptionsValues?.download_by) {
      setSortedDataToDisplay(sortWritingsByEmail(sortedDataToDisplay, asc))
    } else if (option === SortOptionsValues?.date) {
      setSortedDataToDisplay(sortWritingsByDate(sortedDataToDisplay, asc))
    } else if (option === SortOptionsValues?.archiveId) {
      setSortedDataToDisplay(sortWritingsByArchiveId(sortedDataToDisplay, asc))
    }
  }

  const sortDataToDisplay = () => {
    if (search === "") return dataToDisplay
    else {
      return dataToDisplay.filter((data) => {
        const downloadUser = (data?.downloadUser || "").toLowerCase()
        const archiveId = data.archiveId?.toString() || ""
        const lowerSearch = search.toLowerCase()
        return (
          downloadUser.includes(lowerSearch) || archiveId.includes(lowerSearch)
        )
      })
    }
  }

  /* eslint-disable camelcase */
  useEffect(() => {
    if (user && company) {
      window.userpilot.identify(user.id, {
        name: user.firstName + " " + user.lastName,
        email: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
        created_at: user.created_at,
        role: user.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
          company_accounting_type: company.accounting_type,
        },
      })
    }
  }, [user, fiduciaryInformations, user.typology, company])

  useEffect(() => {
    if (selectedCompanyId) {
      setInitialLoad(true)
      dispatch(GetCompanyThunk(selectedCompanyId))
    }
  }, [selectedCompanyId])

  useEffect(() => {
    setSortedDataToDisplay(sortDataToDisplay())
  }, [search, dataToDisplay, setSortedDataToDisplay])

  useEffect(() => {
    if (!archives) return
    const fillDataToDisplay = (writing: Writing): WritingsLineToDisplay[] => {
      const data: WritingsLineToDisplay[] = []
      if (writing.batches_pending_progression_status) {
        const lineBatchesPending: WritingsLineToDisplay = {
          status: "PENDING",
          batchNumber: writing.batches_pending,
          progress: writing.batches_pending_progression_status,
          documentsNumber: 0,
        }
        data.push(lineBatchesPending)
      }

      if (writing.batches_in_writing) {
        const lineBatchesPending: WritingsLineToDisplay = {
          status: "WRITING_READY",
          batchNumber: writing.batches_in_writing,
          progress: 90,
          documentsNumber: 0,
        }
        data.push(lineBatchesPending)
      }

      if (writing.ready_for_archive.count_batchs > 0) {
        const lineBatchesReadyForArchive: WritingsLineToDisplay = {
          status: "READY_FOR_ARCHIVE",
          batchNumber: writing.ready_for_archive.count_batchs,
          documentsNumber: writing.ready_for_archive.count_full_docs,
          progress: 100,
        }
        data.push(lineBatchesReadyForArchive)
      }

      if (writing.archives_created?.length > 0) {
        writing.archives_created.forEach((archive) => {
          const lineArchiveToGenerate: WritingsLineToDisplay = {
            status: "CREATED",
            batchNumber: archive.count_batchs,
            documentsNumber: archive.count_full_docs,
            progress: 100,
            downloadUser: archive.download_user,
            downloadDate: archive.downloaded_at,
            archiveId: archive.id,
          }
          data.push(lineArchiveToGenerate)
        })
      }

      if (writing.archives_generated?.length > 0) {
        writing.archives_generated.forEach((archive) => {
          const lineArchiveGenerated: WritingsLineToDisplay = {
            status: "GENERATED",
            batchNumber: archive.count_batchs,
            documentsNumber: archive.count_full_docs,
            progress: 100,
            downloadUser: archive.download_user,
            downloadDate: archive.downloaded_at,
            archiveId: archive.id,
            accountingSoftwareReference: accountingSoftwareReference || "",
            accountSoftware: accountSoftware || "",
            deactivatedCount: archive.deactivated_full_documents_count,
            processedCount: archive.processed_full_documents_count,
            importStatus: archive.api_import_status,
            importDate: archive.api_import_date,
            importedCoalaPayloadsNumber:
              archive.coala_payloads?.imported_number,
            importErrorCoalaPayloadsNumber:
              archive.coala_payloads?.import_error_number,
            inProgressCoalaPayloadsNumber:
              archive.coala_payloads?.in_progress_number,
            totalCoalaPayloadsNumber: archive.coala_payloads?.total,
            inqomImportInformations: archive.inqom_import_full_document_status,
            acdImportInformations: archive.acd_import_full_document_status,
          }
          data.push(lineArchiveGenerated)
        })
      }

      return data
    }

    setDataToDisplay(fillDataToDisplay(archives))
  }, [archives, setDataToDisplay, selectedFiscalYearId])

  useEffect(() => {
    if (
      !(selectedCompanyId && selectedFiscalYearId && companysFY?.length > 0)
    ) {
      return
    }
    if (initialLoad) {
      callGetAllWritingArchives()
    }

    if (displayInputMaskModal) {
      // avoid refreshing when input mask open
    } else {
      const interval = setInterval(callGetAllWritingArchives, 60000) // CALL EVERY MINUTE FOR REFRESH DATA
      return () => clearInterval(interval)
    }
  }, [
    dispatch,
    selectedCompanyId,
    selectedFiscalYearId,
    companysFY?.length,
    displayInputMaskModal,
  ])

  useEffect(() => {
    if (writingValidationStatus === "success") {
      setDisplayInputMaskModal(true)
      dispatch(ValidateWritingReset())
    }
  }, [writingValidationStatus])

  useEffect(() => {
    if (columnToSort) {
      const currentSort: SortOptionsValues = Object.values(
        SortOptionsValues
      ).indexOf(SortOptionsValues[columnToSort.index])
      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc)(currentSort)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc)(currentSort)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
        setSortedDataToDisplay(sortDataToDisplay())
      }
    }
  }, [columnToSort])

  return (
    <Writings>
      <InputMaskModal
        isDisplayed={displayInputMaskModal}
        onClose={() => {
          setDisplayInputMaskModal(false)
        }}
        onCreateWritingArchive={() => {
          if (isConnectedToApi) {
            dispatch(
              createWritingArchiveThunk(
                selectedFiscalYearId,
                selectedCompanyId,
                selectedSendToApi
              )
            )
          } else {
            dispatch(
              createWritingArchiveThunk(selectedFiscalYearId, selectedCompanyId)
            )
          }

          setDisplayInputMaskModal(false)
        }}
        fiscalYearId={selectedFiscalYearId}
        merchantId={undefined}
      />
      <WritingArchiveDownloadHistoryModal
        isDisplayed={downloadHistoryModalState.isDisplayed}
        onClose={() => {
          setDownloadHistoryModalState({ isDisplayed: false, archiveId: 0 })
        }}
        archiveId={downloadHistoryModalState.archiveId}
      />
      <ConfirmVerificationModalModal
        isDisplayed={displayConfirmVerificationModal}
        onClose={() => {
          setDisplayConfirmVerificationModal(false)
          dispatch(
            getWritingArchivesThunk({
              companyId: selectedCompanyId,
              fiscalYearId: selectedFiscalYearId,
            })
          )
        }}
        onVerifyWritings={() => {
          setDisplayInputMaskModal(true)
        }}
        selectedFiscalYearId={selectedFiscalYearId}
        selectedCompanyId={selectedCompanyId}
        sendToApi={(selectedSendToApi: boolean) => {
          setSelectedSendToApi(selectedSendToApi)
        }}
      />
      {showModalRegenerate.id && (
        <RegenerateArchiveModal
          isDisplayed={showModalRegenerate.show}
          onClose={() => {
            setShowModalRegenrate({ show: false, id: null })
          }}
          archiveId={showModalRegenerate.id}
          companyId={selectedCompanyId}
          fiscalYearId={selectedFiscalYearId}
        />
      )}
      {displayValidationWritingModal && (
        <ValidateWritingModal
          isDisplayed={displayValidationWritingModal}
          onClose={() => {
            setDisplayValidationWritingModal(false)
          }}
          companyId={selectedCompanyId}
          writing_ids={archives.writing_ids}
          fiscalYearId={selectedFiscalYearId}
        />
      )}

      <Content>
        <Header>
          <Ct.SpacedBetweenCenter>
            <Ct.Input
              label="Rechercher"
              value={search}
              suffix={<Search />}
              maxWidth={30}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              shadowed={true}
              noBorder={true}
            />

            {selectedCompanyId && (
              <Ct.Row>
                <FiscalYearPicker
                  companyId={selectedCompanyId}
                  handleNotifs={true}
                  notifsTypes={"writings"}
                  fromWritingPage={true}
                />
              </Ct.Row>
            )}
            <Ct.Spacer width={12} />
          </Ct.SpacedBetweenCenter>
        </Header>
        <Ct.Spacer height={4} />

        <>
          {!dataToDisplay.length ? (
            <TextWrapper>
              <Alert alertType="info">
                <Ct.Text
                  text={intl.formatMessage({
                    id: "writing-archives.no-archives-available.1",
                  })}
                  textStyle={{ lineHeight: 3 }}
                />
                <Ct.Text
                  text={intl.formatMessage({
                    id: "writing-archives.no-archives-available.2",
                  })}
                  textStyle={{ lineHeight: 3 }}
                />
              </Alert>
            </TextWrapper>
          ) : (
            <Table
              intl={intl}
              columns={columns}
              rows={sortedDataToDisplay}
              rowTooltip={rowTooltip}
              rowBackgroundColors={rowBackgroundColors}
              keyBuilder={(row, index) => {
                return `row-${selectedFiscalYearId}-${index}`
              }}
              sortableColumnsLength={columns.length}
              sortableColumnToSkip={1}
              sortingMainFunction={(columnToSort) => {
                setColumnToSort(columnToSort)
              }}
              currentSortColumn={currentChevron}
            />
          )}
        </>
      </Content>
    </Writings>
  )
}

export default CompanyWritingsDisplay

const StyledU = styled.u`
  font-size: 1.75rem;
`

const Writings = styled((props) => <Ct.Card {...props} />)`
  height: 100%;
  width: 100%;
  margin-bottom: 4rem;
  overflow-y: auto;
`

const Content = styled.section`
  height: 100%;
  width: 100%;
`

const Header = styled.section`
  padding: 0 2rem;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`

const ProgressBarWrapper = styled.div`
  width: 25rem;
`

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 1.75rem;
`

const ImportApiCell = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 1.75rem;
`

const StyledLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  align-self: flex-end;
  border-radius: 50%;
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  min-height: 4rem;
  box-sizing: border-box;
  background: ${gradients.blue};
  user-select: none;
  border: none;
  transition: outline 0.3s ease-in-out;
  outline: 2px solid transparent;
`

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`

const ImportTextWrapper = styled.div`
  display: flex;
  justify-content: bottom;
  margin-top: 0.75rem;
`

const StyledUButton = styled.u`
  font-size: 1.75rem;
  cursor: pointer;
`
const StyledReload = styled((props) => <Reload {...props} />)`
  width: 1.5rem;
  height: 1.5rem;
`

const Regenerate = styled.div`
  display: flex;
  cursor: pointer;
  padding-top: 1rem;
  align-items: center;
`
