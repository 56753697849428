export const enableMerchantModificationSave = ({
  modifyLegalEntityForOtherInvoices,
  impactedDocumentReferences,
  hasChangedLegalEntity,
  hasChangedMerchant,
  hasChangedMerchantCode,
  blockedByAlreadyTakenMerchantCode,
  merchantCodeToCreate,
  merchantToCreate,
  merchantCodeId,
  legalEntityToCreate,
  merchantModificationLoadingStatus,
}: {
  modifyLegalEntityForOtherInvoices: boolean | null
  impactedDocumentReferences: string[] | undefined
  hasChangedLegalEntity: boolean
  hasChangedMerchant: boolean
  hasChangedMerchantCode: boolean
  blockedByAlreadyTakenMerchantCode: boolean
  merchantCodeToCreate: string
  merchantToCreate: string
  merchantCodeId: number | undefined
  legalEntityToCreate: string
  merchantModificationLoadingStatus: "loading" | "error" | "success" | "idle"
}) => {
  if (merchantModificationLoadingStatus === "loading") {
    return false
  }
  if (blockedByAlreadyTakenMerchantCode) {
    return false
  }
  if (legalEntityToCreate) {
    return (
      (impactedDocumentReferences?.length || 0) === 0 ||
      (modifyLegalEntityForOtherInvoices !== null && merchantCodeToCreate) ||
      Boolean(merchantCodeId)
    )
  }
  if (hasChangedLegalEntity) {
    return (
      (impactedDocumentReferences?.length || 0) === 0 ||
      modifyLegalEntityForOtherInvoices !== null
    )
  }
  if (merchantToCreate) {
    return merchantCodeToCreate || Boolean(merchantCodeId)
  }

  return hasChangedMerchant || hasChangedMerchantCode
}

export const getLockMerchantCode = ({
  legalEntityToCreate,
  merchantToCreate,
  hasChangedMerchant,
}: {
  legalEntityToCreate: string
  merchantToCreate: string
  hasChangedMerchant: boolean
}) => {
  if (legalEntityToCreate) {
    return false
  }
  if (merchantToCreate) {
    return false
  }

  if (hasChangedMerchant && !merchantToCreate) {
    return true
  }
  return false
}
