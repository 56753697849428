import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"

import { useRNBSelector } from "../../../store/rootReducer"
import { colors } from "../../../styles/design.config"
import {
  createLegalEntityThunk,
  createMerchantCodeThunk,
  createMerchantThunk,
  resetMerchantModification,
  updateLegalEntityThunk,
  updateMerchantCodeThunk,
  updateMerchantThunk,
} from "../../../store/ducks/merchantModification.ducks"
import styled from "styled-components/macro"
import { Button } from "../../Commons/Button"
import { enableMerchantModificationSave } from "../../../utils/merchantModification"

export const ControlBar = ({ onClose }: { onClose: () => void }) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const {
    initialMerchant,
    editedMerchant,
    hasChanged,
    modifyLegalEntityForOtherInvoices,
    impactedDocumentReferences,
    blockedByAlreadyTakenMerchantCode,
    merchantCodeToCreate,
    legalEntityToCreate,
    merchantToCreate,
    merchantCodeId,
    merchantModificationLoadingStatus,
  } = useRNBSelector((state) => ({
    initialMerchant: state.merchantModification.initialMerchant,
    editedMerchant: state.merchantModification.editedMerchant,
    hasChanged:
      JSON.stringify(state.merchantModification.initialMerchant) !==
      JSON.stringify(state.merchantModification.editedMerchant),
    modifyLegalEntityForOtherInvoices:
      state.merchantModification.modifyLegalEntityForOtherInvoices,
    impactedDocumentReferences:
      state.merchantModification.merchantModificationInfo
        ?.impacted_documents_references,
    blockedByAlreadyTakenMerchantCode:
      state.inputMask.blockedByAlreadyTakenMerchantCode,
    merchantCodeToCreate: state.merchantModification.merchantCodeToCreate,
    legalEntityToCreate: state.merchantModification.legalEntityToCreate,
    merchantToCreate: state.merchantModification.merchantToCreate,
    merchantCodeId:
      state.merchantModification.editedMerchant?.legalEntity?.merchant_code_id,
    merchantModificationLoadingStatus:
      state.merchantModification.merchantModificationLoadingStatus,
  }))

  const hasChangedLegalEntity =
    initialMerchant.legalEntity?.id !== editedMerchant.legalEntity?.id

  const hasChangedMerchant =
    initialMerchant.legalEntity?.merchant_id !==
    editedMerchant.legalEntity?.merchant_id

  const hasChangedMerchantCode =
    initialMerchant.legalEntity?.merchant_code_id !==
    editedMerchant.legalEntity?.merchant_code_id

  const isCreatingLegalEntity = Boolean(legalEntityToCreate)
  const isCreatingMerchant = Boolean(merchantToCreate)
  const isCreatingMerchantCode = Boolean(merchantCodeToCreate)

  const enableSave = enableMerchantModificationSave({
    modifyLegalEntityForOtherInvoices,
    impactedDocumentReferences,
    hasChangedLegalEntity,
    hasChangedMerchant,
    hasChangedMerchantCode,
    blockedByAlreadyTakenMerchantCode,
    merchantCodeToCreate,
    merchantToCreate,
    merchantCodeId,
    legalEntityToCreate,
    merchantModificationLoadingStatus,
  })

  const save = () => {
    if (isCreatingLegalEntity) {
      dispatch(createLegalEntityThunk())
    } else if (isCreatingMerchant) {
      dispatch(createMerchantThunk())
    } else if (isCreatingMerchantCode) {
      dispatch(createMerchantCodeThunk())
    } else if (
      hasChangedLegalEntity &&
      ((impactedDocumentReferences?.length || 0) === 0 ||
        modifyLegalEntityForOtherInvoices !== null)
    ) {
      dispatch(updateLegalEntityThunk())
    } else if (hasChangedMerchant) {
      dispatch(updateMerchantThunk())
    } else if (hasChangedMerchantCode) {
      dispatch(updateMerchantCodeThunk())
    }
  }

  return (
    <ControlBarWrapper>
      <ResetLink
        disabled={!hasChanged}
        onClick={() => {
          if (!hasChanged) return

          dispatch(resetMerchantModification())
        }}
      >
        {intl.formatMessage({
          id: "input-mask.merchant-modification.control-bar.cancel",
        })}
      </ResetLink>
      <Ct.Spacer width={4} />
      <Button
        disabled={!enableSave}
        label={intl.formatMessage({
          id: "input-mask.merchant-modification.control-bar.save",
        })}
        colorType="Tertiary"
        width={42}
        onClick={() => {
          save()
        }}
        colorScheme={{
          border: "mist",
          color: "cornflower",
          background: "mist",
        }}
      />
      <Ct.Spacer width={4} />
      <Button
        disabled={!enableSave}
        label={intl.formatMessage({
          id: "input-mask.merchant-modification.control-bar.save-and-close",
        })}
        width={42}
        onClick={() => {
          save()
          onClose()
        }}
      />
    </ControlBarWrapper>
  )
}

const ControlBarWrapper = styled.div`
  display: flex;

  align-items: center;
`

const ResetLink = styled.div<{ disabled: boolean }>`
  color: ${(props) => (props.disabled ? colors.grey : colors.cornflower)};

  text-decoration: underline;
  cursor: ${(props) => (props.disabled ? "not-allowed;" : "pointer")};
`
