import styled from "styled-components/macro"
import { useDispatch } from "react-redux"

import { changeDocumentSource } from "../../store/ducks/inputMask.ducks"
import { useRNBSelector } from "../../store/rootReducer"
import { Select } from "../Commons/Select"
import { DocumentSource } from "../../store/ducks/fullDocuments.ducks"
import { useEffect, useState } from "react"

export const SourceSelectCell = () => {
  const dispatch = useDispatch()

  const { editedFullDocument } = useRNBSelector((state) => ({
    editedFullDocument: state.inputMask.edited_full_document,
  }))

  const sourceOptions = [
    { label: "France", value: "fr" },
    { label: "UE", value: "eu" },
    { label: "Hors UE", value: "world" },
  ]

  const emptyOption = { label: "", value: "" }

  const [sourceOptionForSelect, setSourceOptionForSelect] =
    useState(sourceOptions)
  const [sourceCellLocked, setSourceCellLocked] = useState(false)
  const [selectedOption, setSelectedOption] = useState(
    sourceOptions.find(
      (option) => option.value === editedFullDocument?.document_source
    ) || emptyOption
  )

  useEffect(() => {
    if (editedFullDocument?.goods_or_services === "on_site_consumption") {
      const filteredOptions = sourceOptions.filter(
        (option) => option.value !== "fr"
      )
      setSourceOptionForSelect(filteredOptions)
    } else {
      setSourceOptionForSelect(sourceOptions)
    }
  }, [editedFullDocument?.goods_or_services])

  useEffect(() => {
    if (editedFullDocument?.document_type === "bill_of_exchange") {
      dispatch(changeDocumentSource("fr"))
      setSelectedOption({ label: "France", value: "fr" })
      setSourceCellLocked(true)
    } else setSourceCellLocked(false)
  }, [editedFullDocument])

  return (
    <StyledSelect
      height="8rem"
      intl={null}
      value={selectedOption}
      defaultValue={null}
      options={sourceOptionForSelect}
      onChangeCallback={({ value }) => {
        if (!editedFullDocument) return
        setSelectedOption(
          sourceOptionForSelect.find((option) => option.value === value)!
        )
        dispatch(changeDocumentSource(value as DocumentSource))
      }}
      border="none"
      borderRadius="0"
      centerLabel={true}
      locked={sourceCellLocked}
      disabled={sourceCellLocked}
    />
  )
}

const StyledSelect = styled(Select)`
  width: 100%;
`
