/* eslint-disable camelcase */
import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"

import { useRNBSelector } from "../../../store/rootReducer"
import { colors } from "../../../styles/design.config"
import { Select } from "../../Commons/Select"
import {
  keydownLegalEntityAction,
  keydownMerchantAction,
  selectLegalEntityAction,
  selectMerchantAction,
  setModifyLegalEntityForOtherInvoices,
} from "../../../store/ducks/merchantModification.ducks"
import { Text } from "../../Commons/Text"
import styled from "styled-components/macro"
import { Checkbox } from "../../Commons/Checkbox"
import { normalizeString } from "../../../utils/string"

export interface LegalEntitiesOption {
  value: string
  label: string
}

export const LegalEntitySelect = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const {
    merchantsOfCompany,
    merchantModificationInfo,
    legalEntities,
    selectedLegalEntity,
    buyOrSell,
    hasChangedLegalEntity,
    hasChangedMerchant,
    hasChangedMerchantCode,
    identificationNumbers,
    identificationNumbersLoadingStatus,
    otherInvoicesOnLegalEntity,
    modifyLegalEntityForOtherInvoices,
    legalEntity,
    legalEntityToCreate,
  } = useRNBSelector((state) => ({
    legalEntity: state.merchantModification.editedMerchant.legalEntity,
    legalEntityToCreate: state.merchantModification.legalEntityToCreate,
    merchantsOfCompany: state.merchantCodes.merchantsOfCompany,
    merchantModificationInfo:
      state.merchantModification.merchantModificationInfo,
    legalEntities:
      state.merchantModification.merchantModificationInfo?.legal_entities || [],
    selectedLegalEntity: state.merchantModification.editedMerchant.legalEntity,
    buyOrSell: state.inputMask.initial_full_document?.buy_or_sell || "buy",

    hasChangedLegalEntity:
      state.merchantModification.editedMerchant.legalEntity?.id !==
      state.merchantModification.initialMerchant.legalEntity?.id,
    hasChangedMerchant:
      state.merchantModification.editedMerchant.legalEntity?.merchant_id !==
      state.merchantModification.initialMerchant.legalEntity?.merchant_id,
    hasChangedMerchantCode:
      state.merchantModification.editedMerchant.legalEntity
        ?.merchant_code_id !==
      state.merchantModification.initialMerchant.legalEntity?.merchant_code_id,
    identificationNumbers: state.merchantModification.identificationNumbers,
    identificationNumbersLoadingStatus:
      state.merchantModification.identificationNumbersLoadingStatus,
    otherInvoicesOnLegalEntity:
      (state.merchantModification.merchantModificationInfo
        ?.impacted_documents_references.length || 0) > 0,
    modifyLegalEntityForOtherInvoices:
      state.merchantModification.modifyLegalEntityForOtherInvoices,
  }))

  const identificationNumberDoesNotMatch =
    hasChangedLegalEntity &&
    selectedLegalEntity?.identification_number &&
    !legalEntityToCreate &&
    identificationNumbersLoadingStatus === "finished" &&
    !identificationNumbers.find(
      (i) =>
        i.identification_number === selectedLegalEntity.identification_number
    )

  const legalEntitiesOptionsList = legalEntities.map((le) => ({
    value: le.id.toString(),
    label: `${le.name} ${
      le.identification_number ? `(${le.identification_number})` : ""
    }`,
  }))

  const legalEntitiesOptions = legalEntityToCreate
    ? [...legalEntitiesOptionsList, { value: "0", label: legalEntityToCreate }]
    : legalEntitiesOptionsList

  const value =
    legalEntitiesOptions.find(
      (le) => le.value === selectedLegalEntity?.id.toString()
    ) || null

  const lockSelect =
    !hasChangedLegalEntity && (hasChangedMerchant || hasChangedMerchantCode)

  return (
    <>
      <SelectWrapper>
        <Select
          intl={intl}
          options={legalEntitiesOptions}
          locked={lockSelect}
          disabled={lockSelect}
          value={value}
          defaultValue={null}
          onChangeCallback={(e: LegalEntitiesOption) => {
            const selectedOption =
              legalEntitiesOptions.find((le) => le.value === e.value) || null

            const legalEntity =
              legalEntities.find(
                (le) => le.id.toString() === selectedOption?.value
              ) || null

            if (legalEntity) {
              dispatch(selectLegalEntityAction(legalEntity))
            }
          }}
          onInputChangeCallback={(e) => {
            if (!e) return
            if (!legalEntity) return

            const selectedLegalEntity = legalEntities.find(
              (le) => le.name.toLowerCase() === e.toLowerCase()
            )
            if (selectedLegalEntity) {
              dispatch(selectLegalEntityAction(selectedLegalEntity))
              return
            }

            dispatch(keydownLegalEntityAction(normalizeString(e)))
            const existingMerchant = merchantsOfCompany.find(
              (m) => m.name.toLowerCase() === e.toLowerCase()
            )
            if (existingMerchant) {
              dispatch(
                selectMerchantAction({
                  ...legalEntity,
                  merchant_id: existingMerchant.id,
                  merchant_code_id: existingMerchant.code_id || 0,
                })
              )
            } else {
              dispatch(keydownMerchantAction(normalizeString(e)))
            }
          }}
          label={""}
          domain={""}
          optionType={""}
          borderRadius={"0"}
          customWidth={"30rem"}
          backgroundColor={colors.white}
          overrideBorderColorStr={"transparent"}
          height="10rem"
        />
      </SelectWrapper>
      {identificationNumberDoesNotMatch && (
        <>
          <Text
            text={intl.formatMessage(
              {
                id: `input-mask.merchant-modification.legal-entity.identification-number-not-found`,
              },
              {
                identificationNumber: selectedLegalEntity.identification_number,
              }
            )}
            textStyle={{ color: "amaranth", textAlign: "center" }}
          />
          <Ct.Spacer />
        </>
      )}
      {hasChangedLegalEntity && otherInvoicesOnLegalEntity && (
        <div>
          {legalEntityToCreate ? (
            <Text
              text={intl.formatMessage({
                id: `input-mask.merchant-modification.legal-entity.${buyOrSell}.create`,
              })}
            />
          ) : (
            <Text
              text={intl.formatMessage(
                {
                  id: `input-mask.merchant-modification.legal-entity.${buyOrSell}.change`,
                },
                {
                  invoiceReference:
                    merchantModificationInfo?.current_full_document_reference,
                }
              )}
            />
          )}
          <Ct.Spacer />
          <Text
            text={intl.formatMessage({
              id: `input-mask.merchant-modification.legal-entity.confirm`,
            })}
          />
          <Ct.Spacer />
          <CheckboxesWrapper>
            <Checkbox
              label={intl.formatMessage({
                id: `input-mask.merchant-modification.legal-entity.confirm.yes`,
              })}
              value={modifyLegalEntityForOtherInvoices === true}
              isChecked={modifyLegalEntityForOtherInvoices === true}
              onChange={() => {
                modifyLegalEntityForOtherInvoices === true
                  ? dispatch(setModifyLegalEntityForOtherInvoices(null))
                  : dispatch(setModifyLegalEntityForOtherInvoices(true))
              }}
            />
            <Ct.Spacer />
            <Checkbox
              label={intl.formatMessage({
                id: `input-mask.merchant-modification.legal-entity.confirm.no`,
              })}
              value={modifyLegalEntityForOtherInvoices === false}
              isChecked={modifyLegalEntityForOtherInvoices === false}
              onChange={() => {
                modifyLegalEntityForOtherInvoices === false
                  ? dispatch(setModifyLegalEntityForOtherInvoices(null))
                  : dispatch(setModifyLegalEntityForOtherInvoices(false))
              }}
            />
          </CheckboxesWrapper>
          <Ct.Spacer />
        </div>
      )}
    </>
  )
}

const CheckboxesWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const SelectWrapper = styled.div`
  align-self: center;
`
