import {
  components,
  default as ReactSelect,
  DropdownIndicatorProps,
  MultiValue,
  SingleValue,
  SingleValueProps,
  PlaceholderProps,
  OptionProps,
} from "react-select"
import ReactTooltip from "react-tooltip"

import { boxShadow, colors } from "../../styles/design.config"
import { ReactComponent as ChevronDown } from "../../assets/chevron-down.svg"
import { ReactComponent as Lock } from "../../assets/lock.svg"

import { ReactComponent as Down } from "../../assets/down.svg"
import styled from "styled-components/macro"
import { IntlShape } from "react-intl"
import { useEffect, useState } from "react"
import { ReactComponent as LocationArrow } from "../../assets/location-arrow.svg"
import { ReactComponent as StringAsc } from "../../assets/sort-icons/string-asc.svg"
import { ReactComponent as StringDesc } from "../../assets/sort-icons/string-desc.svg"
import { ReactComponent as NumberAsc } from "../../assets/sort-icons/numbers-asc.svg"
import { ReactComponent as NumberDesc } from "../../assets/sort-icons/numbers-desc.svg"
import { ReactComponent as DateAsc } from "../../assets/sort-icons/date-asc.svg"
import { ReactComponent as DateDesc } from "../../assets/sort-icons/date-desc.svg"
import { Spacer, RowCenter, Text } from "ldlj"
import { FilterOptionOption } from "react-select/dist/declarations/src/filters"

export interface SelectProps {
  options: Array<Option<string>>
  value: Option<string> | null
  label?: string
  domain?: string
  optionType?: string
  defaultValue?: Option<string> | null
  field?: unknown
  disabled?: boolean
  selectType?: string
  onChangeCallback?: (value: Option<string>) => void
  onInputChangeCallback?: (value: string) => void
  shadowed?: boolean
  isClearable?: boolean
  dataCy?: string
  customWidth?: string
  intl: IntlShape | null
  asc?: boolean
  alignSelf?: string
  isSearch?: boolean
  height?: string
  maxwidth?: string
  overflow?: string
  locked?: boolean
  hasPlusButton?: boolean
  hasTwoButton?: boolean
  moreSpace?: boolean
  prefixString?: string
  filterOption?:
    | ((
        option: FilterOptionOption<Option<string>>,
        inputValue: string
      ) => boolean)
    | null
  highlightValues?: {
    highlight: boolean
    searchString: string
  }
  border?: string
  backgroundColor?: string
  borderRadius?: string
  moveTextFromLeft?: string
  overrideBorderColor?: keyof typeof colors
  overrideBorderColorStr?: string
  warning?: boolean
  error?: boolean
  noOptionsMessage?: string
  reducePadding?: boolean
  overrideBorder?: string
  centerLabel?: boolean
}

export type Option<T extends string> = {
  value: T
  label: string
  disabled?: boolean
  sortType?: "string" | "number" | "date"
  tooltipText?: string
}
export type OptionList<T extends string> = Array<Option<T>>

export function createOptionList<T extends string>(
  optionList: Array<[T, string, boolean?]>
): OptionList<T> {
  return optionList.map(([key, value, disabled = false, type = undefined]) => ({
    value: key,
    label: value.toLowerCase(),
    disabled: disabled,
    sortType: type,
  }))
}

export const emptyOptionValue = { value: "", label: "" }

export const Select = (props: SelectProps) => {
  const {
    options,
    value,
    field,
    label,
    domain,
    optionType,
    selectType = "input",
    disabled,
    defaultValue,
    onChangeCallback,
    onInputChangeCallback,
    shadowed = false,
    isClearable = false,
    dataCy,
    customWidth,
    intl,
    asc,
    alignSelf,
    isSearch,
    height,
    maxwidth,
    overflow,
    locked,
    filterOption,
    highlightValues,
    backgroundColor = "white",
    border,
    borderRadius,
    hasPlusButton,
    hasTwoButton,
    moreSpace,
    moveTextFromLeft,
    overrideBorderColor,
    overrideBorderColorStr,
    warning,
    error,
    noOptionsMessage = "Pas d'options disponibles",
    prefixString,
    reducePadding = false,
    overrideBorder,
    centerLabel,
  } = props

  const [isFocused, setIsFocused] = useState(false)
  const CustomPlaceholder = () => <></>
  const CustomPlaceholderPrefix = ({
    children,
    ...props
  }: PlaceholderProps<Option<string>>) => (
    <components.Placeholder {...props}>
      <span style={{ color: colors.cornflower }}>{prefixString}</span>
    </components.Placeholder>
  )

  const DropdownIndicatorSort = (
    props: DropdownIndicatorProps<Option<string>>
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        {locked ? <StyledLock /> : <StyledChevronDown />}
      </components.DropdownIndicator>
    )
  }

  const DropdownIndicator = (props: DropdownIndicatorProps<Option<string>>) => {
    return (
      <components.DropdownIndicator {...props}>
        {locked ? <StyledLock /> : <StyledDown disabled={disabled} />}
      </components.DropdownIndicator>
    )
  }

  const OptionComponent = ({
    children,
    ...props
  }: OptionProps<Option<string>>) => (
    <components.Option {...props}>
      <OptionPadding data-tip={props.data.tooltipText}>
        {children}
      </OptionPadding>
      {props.data.tooltipText && (
        <ReactTooltip
          delayShow={300}
          effect={"solid"}
          eventOff="mouseleave scroll mousewheel blur"
          place="bottom"
        />
      )}
    </components.Option>
  )

  const SingleValueComponent = ({
    children,
    ...props
  }: SingleValueProps<Option<string>>) => (
    <components.SingleValue {...props}>
      <RowCenter>
        <Text
          textStyle={{ color: "slateGrey" }}
          text={intl ? intl.formatMessage({ id: "office.sort" }) : ""}
        />
        <Spacer width={0.5} />
        <StyledSpan data-tip={props.data.tooltipText}>{children}</StyledSpan>
        <ReactTooltip
          delayShow={300}
          effect={"solid"}
          eventOff="mouseleave scroll mousewheel blur"
        />

        {props?.data?.sortType && (
          <>
            {props.data.sortType === "number" && asc ? (
              <NumberAsc id={"sortIcon"} />
            ) : props.data.sortType === "number" && !asc ? (
              <NumberDesc id={"sortIcon"} />
            ) : props.data.sortType === "string" && asc ? (
              <StringAsc id={"sortIcon"} />
            ) : props.data.sortType === "string" && !asc ? (
              <StringDesc id={"sortIcon"} />
            ) : props.data.sortType === "date" && asc ? (
              <DateAsc id={"sortIcon"} />
            ) : props.data.sortType === "date" && !asc ? (
              <DateDesc id={"sortIcon"} />
            ) : (
              <LocationArrow />
            )}
          </>
        )}
      </RowCenter>
    </components.SingleValue>
  )

  const SingleValueComponentPrefix = ({
    children,
    ...props
  }: SingleValueProps<Option<string>>) => (
    <components.SingleValue {...props}>
      <RowCenter>
        <span style={{ color: colors.cornflower }}>{prefixString}</span>
        <Spacer width={0.5} />
        <span>{children}</span>
      </RowCenter>
    </components.SingleValue>
  )

  useEffect(() => {
    if (!highlightValues?.highlight) {
      return
    }

    const search = highlightValues?.searchString.toLowerCase()

    const arrayElements = Array.from(
      document.getElementsByClassName(
        "scrollable-children__single-value"
      ) as HTMLCollectionOf<HTMLElement>
    )
    const filteredElements = arrayElements.filter(
      (e) =>
        e.innerText.toLowerCase().includes(search) ||
        search.includes(e.innerText.toLowerCase())
    )

    filteredElements.forEach((e) => {
      const innerText = e.innerText.toLowerCase()
      if (
        e.innerHTML !== e.innerText &&
        !innerText.includes(search) &&
        !search.includes(innerText)
      ) {
        e.innerHTML = e.innerText
      } else if (
        e.innerText?.toLowerCase().includes(search) ||
        search.includes(innerText)
      ) {
        const parts = innerText.split(new RegExp(`(${search})`, "gi"))

        let newText = ""

        for (const part of parts) {
          if (part.includes(search) || search.includes(part)) {
            newText += `<span style="background-color: rgba(255, 197, 66, 0.3); padding: '0.5rem 0rem'">${part.toUpperCase()}</span>`
          } else {
            newText += `<span>${part.toUpperCase()}</span>`
          }
        }

        e.innerHTML = newText
      }
    })
  }, [highlightValues])

  return (
    <StyledSelect
      isFocused={isFocused}
      value={value ? value.value : null}
      disabled={disabled || false}
      selectType={selectType}
      shadowed={shadowed}
      myDefaultValue={defaultValue ? defaultValue.value : null}
      customWidth={customWidth}
      alignSelf={alignSelf}
      isSearch={isSearch}
      height={height}
      maxwidth={maxwidth}
      overflow={overflow}
      locked={locked}
      border={border}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      hasPlusButton={hasPlusButton || false}
      moreSpace={moreSpace || false}
      hasTwoButton={hasTwoButton || false}
      moveTextFromLeft={moveTextFromLeft}
      overrideBorderColor={overrideBorderColor}
      overrideBorderColorStr={overrideBorderColorStr}
      overrideBorder={overrideBorder}
      warning={warning}
      error={error}
      prefixString={prefixString}
      reducePadding={reducePadding}
      centerLabel={centerLabel || false}
    >
      {selectType === "sort" ? (
        <ReactSelect
          {...field}
          classNamePrefix="react-select-container"
          noOptionsMessage={() => {
            return noOptionsMessage
          }}
          onFocus={() => {
            setIsFocused(true)
          }}
          onBlur={() => {
            setIsFocused(false)
          }}
          value={
            (field as { value: string })?.value || value ? value : defaultValue
          }
          filterOption={filterOption}
          isDisabled={disabled}
          isClearable={isClearable}
          options={options}
          components={{
            Placeholder: CustomPlaceholder,
            DropdownIndicator: DropdownIndicatorSort,
            SingleValue: SingleValueComponent,
            Option: OptionComponent,
          }}
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 2 }), // Fixes the overlapping problem of the component
            option: (defaultOptions, selectState) => ({
              ...defaultOptions,
              backgroundColor: selectState.isSelected
                ? colors.cornflower
                : colors.white,
              "&:hover": {
                backgroundColor: colors.cornflower,
                color: "white",
              },
              padding: "0",
            }),
            control: (defaultOptions) => ({
              ...defaultOptions,
              backgroundColor: colors.white,
              borderRadius: "1.25rem",
              border: "0px",
            }),
            dropdownIndicator: (defaultOptions) => ({
              ...defaultOptions,
            }),
            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          onChange={(
            e: SingleValue<Option<string>> | MultiValue<Option<string>>
          ) => {
            if (e && !locked) {
              return onChangeCallback && onChangeCallback(e as Option<string>)
            }
          }}
          onInputChange={(e) => {
            if (onInputChangeCallback && !locked) {
              onInputChangeCallback(e)
            }
          }}
          data-cy={dataCy}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          closeMenuOnScroll={(e: Event) => {
            if (
              (e?.target as Element)?.className.includes(
                "react-select-container"
              )
            ) {
              return false
            } else {
              return true
            }
          }}
        />
      ) : selectType === "selectWithPrefix" ? (
        <>
          <ReactSelect
            {...field}
            classNamePrefix="scrollable-children"
            noOptionsMessage={() => {
              return noOptionsMessage
            }}
            onFocus={() => {
              setIsFocused(true)
            }}
            isOptionDisabled={(option) => option.disabled as boolean}
            onBlur={() => {
              setIsFocused(false)
            }}
            value={
              ((field as { value: string })?.value || value) &&
              value?.value?.length &&
              value?.value?.length > 0
                ? value
                : defaultValue
            }
            filterOption={filterOption}
            isDisabled={disabled}
            isClearable={isClearable}
            options={options}
            components={{
              Placeholder: CustomPlaceholderPrefix,
              DropdownIndicator,
              SingleValue: SingleValueComponentPrefix,
              Option: OptionComponent,
            }}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 2 }), // Fixes the overlapping problem of the component
              option: (defaultOptions, selectState) => ({
                ...defaultOptions,
                backgroundColor: selectState.isSelected
                  ? colors.cornflower
                  : selectState.isFocused
                  ? colors.mist
                  : colors.white,
                "&:hover": {
                  backgroundColor: colors.cornflower,
                  color: "white",
                },
                padding: "0",
              }),
              control: (defaultOptions) => ({
                ...defaultOptions,
                backgroundColor: colors.white,
                borderRadius: "1.25rem",
              }),
              dropdownIndicator: (defaultOptions) => ({
                ...defaultOptions,
              }),
              menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            onChange={(
              e: SingleValue<Option<string>> | MultiValue<Option<string>> | null
            ) => {
              if (locked) {
                return
              }
              if (e) {
                return onChangeCallback && onChangeCallback(e as Option<string>)
              } else {
                return (
                  onChangeCallback && onChangeCallback({ value: "", label: "" })
                )
              }
            }}
            onInputChange={(e) => {
              if (locked) {
                return
              }
              if (onInputChangeCallback) {
                onInputChangeCallback(e)
              }
            }}
            data-cy={dataCy}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            closeMenuOnScroll={(e: Event) => {
              if (
                (e?.target as Element)?.className &&
                (e?.target as Element)?.className.includes(
                  "scrollable-children"
                )
              ) {
                return false
              } else {
                return true
              }
            }}
          />
          <label htmlFor={label}>
            {intl && optionType && domain === ""
              ? optionType
              : intl && optionType && domain !== ""
              ? intl.formatMessage({ id: `${domain}.${optionType}` })
              : ""}
          </label>
        </>
      ) : (
        <>
          <ReactSelect
            {...field}
            classNamePrefix="scrollable-children"
            noOptionsMessage={() => {
              return noOptionsMessage
            }}
            onFocus={() => {
              setIsFocused(true)
            }}
            isOptionDisabled={(option) => option.disabled as boolean}
            onBlur={() => {
              setIsFocused(false)
            }}
            value={
              ((field as { value: string })?.value || value) &&
              value?.value?.length &&
              value?.value?.length > 0
                ? value
                : defaultValue
            }
            filterOption={filterOption}
            isDisabled={disabled}
            isClearable={isClearable}
            options={options}
            components={{
              Placeholder: CustomPlaceholder,
              DropdownIndicator,
              Option: OptionComponent,
            }}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 2 }), // Fixes the overlapping problem of the component
              option: (defaultOptions, selectState) => ({
                ...defaultOptions,
                backgroundColor: selectState.isSelected
                  ? colors.cornflower
                  : selectState.isFocused
                  ? colors.mist
                  : colors.white,
                "&:hover": {
                  backgroundColor: colors.cornflower,
                  color: "white",
                },
                padding: "0",
              }),
              control: (defaultOptions) => ({
                ...defaultOptions,
                backgroundColor: colors.white,
                borderRadius: "1.25rem",
              }),
              dropdownIndicator: (defaultOptions) => ({
                ...defaultOptions,
              }),
              menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
              valueContainer: (defaultOptions) => ({
                ...defaultOptions,
                ...(centerLabel && { justifyContent: "center" }),
              }),
            }}
            onChange={(
              e: SingleValue<Option<string>> | MultiValue<Option<string>> | null
            ) => {
              if (locked) {
                return
              }

              if (e) {
                return onChangeCallback && onChangeCallback(e as Option<string>)
              } else {
                return (
                  onChangeCallback && onChangeCallback({ value: "", label: "" })
                )
              }
            }}
            onInputChange={(e) => {
              if (locked) {
                return
              }

              if (onInputChangeCallback) {
                onInputChangeCallback(e)
              }
            }}
            data-cy={dataCy}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            closeMenuOnScroll={(e: Event) => {
              if (
                (e?.target as Element)?.className &&
                (e?.target as Element)?.className.includes(
                  "scrollable-children"
                )
              ) {
                return false
              } else {
                return true
              }
            }}
          />
          <label htmlFor={label}>
            {intl && optionType && domain === ""
              ? optionType
              : intl && optionType && domain !== ""
              ? intl.formatMessage({ id: `${domain}.${optionType}` })
              : ""}
          </label>
        </>
      )}
    </StyledSelect>
  )
}

const StyledSpan = styled.span`
  font-weight: bold;
  color: ${colors.navy};
`
const OptionPadding = styled.div`
  padding: 8px 12px;
`

interface DropDownOptions {
  disabled?: boolean
}

const StyledDown = styled(Down)<DropDownOptions>`
  & path {
    fill: ${({ disabled }) =>
      disabled ? colors.greenLight : colors.cornflower};
  }
  width: 2rem;
  height: 2rem;
`

export interface Selector {
  value: string | null
  disabled: boolean
  isFocused: boolean
  selectType: string
  shadowed: boolean
  myDefaultValue: string | null
  customWidth: string | undefined
  alignSelf?: string
  isSearch?: boolean
  height?: string
  maxwidth?: string
  overflow?: string
  locked?: boolean
  border?: string
  backgroundColor?: string
  borderRadius?: string
  hasPlusButton: boolean
  hasTwoButton: boolean
  moreSpace: boolean
  moveTextFromLeft?: string
  overrideBorderColor?: keyof typeof colors
  overrideBorderColorStr?: string
  warning?: boolean
  error?: boolean
  prefixString?: string
  reducePadding?: boolean
  overrideBorder?: string
  centerLabel?: boolean
}

export const StyledSelect = styled.div<Selector>`
  display: flex;
  width: ${({ selectType, customWidth }) =>
    selectType === "sort" ? "auto" : customWidth ? customWidth : "100%"};
  max-width: ${({ maxwidth }) => maxwidth || "auto"};
  flex-direction: row;
  flex-grow: ${({ selectType, customWidth }) =>
    selectType === "sort" ? "" : customWidth ? "unset" : 1};
  position: relative;
  font-family: "Roboto", sans-serif;
  border-radius: 1.25rem;
  align-self: ${({ alignSelf }) => (alignSelf ? alignSelf : "flex-end")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  text-indent: ${({ moveTextFromLeft }) =>
    moveTextFromLeft ? moveTextFromLeft : "unset"};

  & label {
    position: absolute;
    left: 1.25rem;
    top: ${({ disabled, isFocused, height, value, locked }) =>
      (value?.length === 0 && !disabled && !isFocused) || locked
        ? "2rem"
        : height
        ? "1.5rem"
        : value && value?.length > 0
        ? "-0.75rem"
        : "-0.75rem"};
    transition: top 0.15s ease-in-out, color 0.5s ease-in-out,
      font-size 0.5s ease-in-out;
    color: ${({ disabled, isFocused, warning, error, locked }) =>
      disabled && !locked
        ? colors.hawkes
        : warning
        ? `${colors.orange}`
        : error
        ? `${colors.amaranth}`
        : isFocused
        ? colors.cornflower
        : colors.rock};

    font-size: ${({ value, myDefaultValue }) =>
      value?.length === 0 && !myDefaultValue ? "1.75rem" : "1.5rem"};

    padding: 0 0.5rem;
    pointer-events: none;
    user-select: none;
    background-color: ${({ backgroundColor }) => backgroundColor};
  }

  & input:focus {
    + label {
      color: ${({ warning, error }) =>
        warning
          ? `${colors.orange}`
          : error
          ? `${colors.amaranth}`
          : `${colors.cornflower}`};
      top: -0.75rem;
      font-size: 1.5rem;
    }

    + label + div > div > svg > path {
      fill: ${({ warning, error }) =>
        warning
          ? `${colors.orange}`
          : error
          ? `${colors.amaranth}`
          : `${colors.cornflower}`};
    }
  }

  & [class*="container"] {
    flex-grow: ${({ selectType }) => (selectType === "sort" ? "none" : "1")};
  }

  & [class*="indicatorContainer"] {
    padding: ${({ height, reducePadding }) =>
      reducePadding ? "0" : height === "4rem" ? "4px" : "8px"};
    padding-left: ${({ hasPlusButton, hasTwoButton, moreSpace }) =>
      hasPlusButton || hasTwoButton || moreSpace ? "0" : "auto"};
    margin-right: ${({ hasPlusButton, hasTwoButton }) =>
      hasTwoButton ? "3.5rem" : hasPlusButton ? "2rem" : "auto"};
  }

  & [class*="indicatorContainer"] svg {
    transition: 0.5s fill ease-in-out;
    fill: ${({ disabled, isFocused, locked }) =>
      disabled && !locked
        ? `${colors.hawkes}`
        : isFocused
        ? `${colors.cornflower}`
        : `${colors.rock}`};
    width: 3rem;
    height: 3rem;
  }

  & div > [class*="IndicatorsContainer"] {
    flex-grow: 0;
    svg {
      fill: ${({ warning, error }) =>
        warning
          ? `${colors.orange}`
          : error
          ? `${colors.amaranth}`
          : `${colors.cornflower}`};
    }
  }

  & [class*="indicatorContainer"]:hover {
    cursor: pointer;
    svg {
      fill: ${({ warning, error }) =>
        warning
          ? `${colors.orange}`
          : error
          ? `${colors.orange}`
          : `${colors.cornflower}`};
    }
  }

  & div > [class*="control"] {
    flex-grow: 1;
    box-shadow: ${({ shadowed }) => (shadowed ? `${boxShadow}` : "0 0 0 0")};
    color: ${colors.navy};
    color: ${({ warning, error }) =>
      warning
        ? `${colors.orange}`
        : error
        ? `${colors.amaranth}`
        : `${colors.navy}`};
    height: ${({ isSearch, height }) =>
      isSearch ? "5rem" : height ? height : "6rem"};
    outline: none;
    border-radius: ${({ borderRadius }) => borderRadius || "1.25rem"};

    font-size: 1.75rem;
    width: 100%;
    border: ${({
      disabled,
      isFocused,
      selectType,
      border,
      overrideBorderColor,
      overrideBorderColorStr,
      warning,
      error,
      overrideBorder,
      locked,
    }) =>
      border || selectType === "sort"
        ? `0px`
        : overrideBorderColor
        ? `2px solid ${colors[overrideBorderColor]}`
        : overrideBorderColorStr
        ? `2px solid ${overrideBorderColorStr}`
        : overrideBorder
        ? overrideBorder
        : disabled && !locked
        ? `1px solid ${colors.hawkes}`
        : warning
        ? `1px solid ${colors.orange}`
        : error
        ? `1px solid ${colors.amaranth}`
        : `1px solid ${isFocused ? colors.cornflower : colors.rock}`};
    box-sizing: border-box;
    max-width: ${({ maxwidth }) => (maxwidth ? maxwidth : "")};

    min-height: ${({ isSearch, height }) =>
      isSearch ? "4rem" : height ? height : "4.75rem"};
    background-color: ${({ backgroundColor }) => backgroundColor};
    transition: 0.5s background-color ease-in-out;
  }

  & div > [class*="MenuList"] {
    max-height: 27rem;
  }

  & div > [class*="control"] > [class*="ValueContainer"] {
    height: 100%;
    width: 100%;
    overflow: ${({ overflow }) => (overflow ? overflow : "visible")};
  }

  & div > [class*="control"] > [class*="container"] {
    padding: ${({ reducePadding }) => (reducePadding ? "2px 0" : "2px 8px")};
  }

  &
    div
    > [class*="control"]
    > [class*="ValueContainer"]
    > [class*="singleValue"] {
    color: ${({ disabled, warning, error, locked }) =>
      disabled && !locked
        ? `${colors.hawkes}`
        : warning
        ? `${colors.orange}`
        : error
        ? `${colors.amaranth}`
        : `${colors.navy}`};
  }

  #sortIcon {
    width: 3rem;
    height: 3rem;
    padding-left: 0.75rem;
  }

  & div > [class*="indicatorSeparator"] {
    display: ${({ selectType }) => (selectType === "sort" ? "block" : "none")};
  }

  & div > [class*="Input"] > input {
    caret-color: transparent;
  }

  & div > [class*="Input"]:focus + label {
    top: -0.75rem;
  }
`

const StyledChevronDown = styled(ChevronDown)`
  width: 2rem;
  height: 2rem;
`
const StyledLock = styled(Lock)`
  width: 2rem;
  height: 2rem;
`
