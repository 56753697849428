import axios, { AxiosError } from "axios"
import { Dispatch, RNBThunkAction } from "../store.config"
import { AnyAction } from "redux"
import { RootState } from "../rootReducer"
import { changeLegalEntity, changeMerchant } from "./inputMask.ducks"
import { buyOrSell } from "../../utils/company"
import {
  GetAllMerchantsOfCompanyForCodes,
  GetCompanyMerchantCodesDataThunk,
} from "./merchantCodes.ducks"

const enum MerchantModificationActionsEnum {
  GET_IDENTIFICATION_NUMBERS_ATTEMPT = "MERCHANT_MODIFICATION/getIdentificationNumbersAttempt",
  GET_IDENTIFICATION_NUMBERS_SUCCESS = "MERCHANT_MODIFICATION/getIdentificationNumbersSuccess",
  GET_IDENTIFICATION_NUMBERS_ERROR = "MERCHANT_MODIFICATION/getIdentificationNumbersErrror",

  GET_MERCHANT_MODIFICATION_INFO_ATTEMPT = "MERCHANT_MODIFICATION/getMerchantModificationInfoAttempt",
  GET_MERCHANT_MODIFICATION_INFO_SUCCESS = "MERCHANT_MODIFICATION/getMerchantModificationInfoSuccess",
  GET_MERCHANT_MODIFICATION_INFO_ERROR = "MERCHANT_MODIFICATION/getMerchantModificationInfoErrror",

  UPDATE_LEGAL_ENTITY_ATTEMPT = "MERCHANT_MODIFICATION/updateLegalEntityAttempt",
  UPDATE_LEGAL_ENTITY_SUCCESS = "MERCHANT_MODIFICATION/updateLegalEntitySuccess",
  UPDATE_LEGAL_ENTITY_ERROR = "MERCHANT_MODIFICATION/updateLegalEntityErrror",

  UPDATE_MERCHANT_ATTEMPT = "MERCHANT_MODIFICATION/updateMerchantAttempt",
  UPDATE_MERCHANT_SUCCESS = "MERCHANT_MODIFICATION/updateMerchantSuccess",
  UPDATE_MERCHANT_ERROR = "MERCHANT_MODIFICATION/updateMerchantErrror",

  UPDATE_MERCHANT_CODE_ATTEMPT = "MERCHANT_MODIFICATION/updateMerchantCodeAttempt",
  UPDATE_MERCHANT_CODE_SUCCESS = "MERCHANT_MODIFICATION/updateMerchantCodeSuccess",
  UPDATE_MERCHANT_CODE_ERROR = "MERCHANT_MODIFICATION/updateMerchantCodeErrror",

  CREATE_LEGAL_ENTITY_ATTEMPT = "MERCHANT_MODIFICATION/createLegalEntityAttempt",
  CREATE_LEGAL_ENTITY_SUCCESS = "MERCHANT_MODIFICATION/createLegalEntitySuccess",
  CREATE_LEGAL_ENTITY_ERROR = "MERCHANT_MODIFICATION/createLegalEntityErrror",

  CREATE_MERCHANT_CODE_ATTEMPT = "MERCHANT_MODIFICATION/createMerchantCodeAttempt",
  CREATE_MERCHANT_CODE_SUCCESS = "MERCHANT_MODIFICATION/createMerchantCodeSuccess",
  CREATE_MERCHANT_CODE_ERROR = "MERCHANT_MODIFICATION/createMerchantCodeErrror",

  CREATE_MERCHANT_ATTEMPT = "MERCHANT_MODIFICATION/createMerchantAttempt",
  CREATE_MERCHANT_SUCCESS = "MERCHANT_MODIFICATION/createMerchantSuccess",
  CREATE_MERCHANT_ERROR = "MERCHANT_MODIFICATION/createMerchantErrror",

  SELECT_LEGAL_ENTITY = "MERCHANT_MODIFICATION/selectLegalEntity",
  SELECT_MERCHANT = "MERCHANT_MODIFICATION/selectMerchant",
  SELECT_MERCHANT_CODE = "MERCHANT_MODIFICATION/selectMerchantCode",
  KEYDOWN_LEGAL_ENTITY = "MERCHANT_MODIFICATION/keydownLegalEntity",
  KEYDOWN_MERCHANT_CODE = "MERCHANT_MODIFICATION/keydownMerchantCode",
  KEYDOWN_MERCHANT = "MERCHANT_MODIFICATION/keydownMerchant",

  SET_INITIAL_LEGAL_ENTITY = "MERCHANT_MODIFICATION/setInitialLegalEntity",
  SET_MODIFY_OTHER_INVOICES_LEGAL_ENTITY = "MERCHANT_MODIFICATION/setModifyLegalEntityForOtherInvoices",
  RESET_MERCHANT_MODIFICATION = "MERCHANT_MODIFICATION/resetMerchantModification",
  REINITIALIZE_MERCHANT_MODIFICATION = "MERCHANT_MODIFICATION/reinitializeLegalEntity",
}

type MerchantModificationActionsType = ReturnType<
  | typeof getIdentificationNumbersAttemptAction
  | typeof getIdentificationNumbersSuccessAction
  | typeof getIdentificationNumbersErrorAction
  | typeof updateLegalEntityAttemptAction
  | typeof updateLegalEntitySuccessAction
  | typeof updateLegalEntityErrorAction
  | typeof updateMerchantAttemptAction
  | typeof updateMerchantSuccessAction
  | typeof updateMerchantErrorAction
  | typeof updateMerchantCodeAttemptAction
  | typeof updateMerchantCodeSuccessAction
  | typeof updateMerchantCodeErrorAction
  | typeof createMerchantCodeAttemptAction
  | typeof createMerchantCodeSuccessAction
  | typeof createMerchantCodeErrorAction
  | typeof createLegalEntityAttemptAction
  | typeof createLegalEntitySuccessAction
  | typeof createLegalEntityErrorAction
  | typeof createMerchantAttemptAction
  | typeof createMerchantSuccessAction
  | typeof createMerchantErrorAction
  | typeof getMerchantModificationInfoAttemptAction
  | typeof getMerchantModificationInfoSuccessAction
  | typeof getMerchantModificationInfoErrorAction
  | typeof selectLegalEntityAction
  | typeof selectMerchantAction
  | typeof selectMerchantCodeAction
  | typeof keydownMerchantCodeAction
  | typeof keydownLegalEntityAction
  | typeof keydownMerchantAction
  | typeof setInitialLegalEntityAction
  | typeof setModifyLegalEntityForOtherInvoices
  | typeof resetMerchantModification
  | typeof reinitializeMerchantModification
>

export const getIdentificationNumbersAttemptAction = () =>
  ({
    type: MerchantModificationActionsEnum.GET_IDENTIFICATION_NUMBERS_ATTEMPT,
  } as const)

export const getIdentificationNumbersSuccessAction = (
  payload: FullDocumentIdentificationNumber[]
) =>
  ({
    type: MerchantModificationActionsEnum.GET_IDENTIFICATION_NUMBERS_SUCCESS,
    payload,
  } as const)
export const getIdentificationNumbersErrorAction = (e: AxiosError) =>
  ({
    type: MerchantModificationActionsEnum.GET_IDENTIFICATION_NUMBERS_ERROR,
  } as const)

export const getMerchantModificationInfoAttemptAction = () =>
  ({
    type: MerchantModificationActionsEnum.GET_MERCHANT_MODIFICATION_INFO_ATTEMPT,
  } as const)

export const getMerchantModificationInfoSuccessAction = (
  payload: MerchantModificationInfo
) =>
  ({
    type: MerchantModificationActionsEnum.GET_MERCHANT_MODIFICATION_INFO_SUCCESS,
    payload,
  } as const)
export const getMerchantModificationInfoErrorAction = (e: AxiosError) =>
  ({
    type: MerchantModificationActionsEnum.GET_MERCHANT_MODIFICATION_INFO_ERROR,
  } as const)

export const updateLegalEntityAttemptAction = () =>
  ({
    type: MerchantModificationActionsEnum.UPDATE_LEGAL_ENTITY_ATTEMPT,
  } as const)

export const updateLegalEntitySuccessAction = (payload: {
  modifyLegalEntityForOtherInvoices: boolean
}) =>
  ({
    type: MerchantModificationActionsEnum.UPDATE_LEGAL_ENTITY_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: payload.modifyLegalEntityForOtherInvoices
        ? "input-mask.merchant-modification.legal-entity-update-multiple-success.title"
        : "input-mask.merchant-modification.legal-entity-update-success.title",
      bodyKey: payload.modifyLegalEntityForOtherInvoices
        ? "input-mask.merchant-modification.legal-entity-update-success.body"
        : "input-mask.merchant-modification.legal-entity-update-multiple-success.body",
    },
    payload,
  } as const)
export const updateLegalEntityErrorAction = (e: AxiosError) =>
  ({
    type: MerchantModificationActionsEnum.UPDATE_LEGAL_ENTITY_ERROR,
  } as const)

export const updateMerchantAttemptAction = () =>
  ({
    type: MerchantModificationActionsEnum.UPDATE_MERCHANT_ATTEMPT,
  } as const)

export const updateMerchantSuccessAction = (payload: {
  buyOrSell: buyOrSell
  legalEntity: LegalEntity
}) =>
  ({
    type: MerchantModificationActionsEnum.UPDATE_MERCHANT_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `input-mask.merchant-modification.merchant-update.${payload.buyOrSell}.success.title`,
      bodyKey: `input-mask.merchant-modification.merchant-update.${payload.buyOrSell}.success.body`,
    },
    payload,
  } as const)
export const updateMerchantErrorAction = (e: AxiosError) =>
  ({
    type: MerchantModificationActionsEnum.UPDATE_MERCHANT_ERROR,
  } as const)

export const updateMerchantCodeAttemptAction = () =>
  ({
    type: MerchantModificationActionsEnum.UPDATE_MERCHANT_CODE_ATTEMPT,
  } as const)

export const updateMerchantCodeSuccessAction = (payload: {
  buyOrSell: buyOrSell
  legalEntity: LegalEntity
}) =>
  ({
    type: MerchantModificationActionsEnum.UPDATE_MERCHANT_CODE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `input-mask.merchant-modification.merchant-code-update.${payload.buyOrSell}.success.title`,
      bodyKey: `input-mask.merchant-modification.merchant-code-update.${payload.buyOrSell}.success.body`,
    },
    payload,
  } as const)
export const updateMerchantCodeErrorAction = (e: AxiosError) =>
  ({
    type: MerchantModificationActionsEnum.UPDATE_MERCHANT_CODE_ERROR,
  } as const)

export const createMerchantCodeAttemptAction = () =>
  ({
    type: MerchantModificationActionsEnum.CREATE_MERCHANT_CODE_ATTEMPT,
  } as const)

export const createMerchantCodeSuccessAction = (payload: {
  buyOrSell: buyOrSell
  legalEntity: LegalEntity
}) =>
  ({
    type: MerchantModificationActionsEnum.CREATE_MERCHANT_CODE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `input-mask.merchant-modification.merchant-code-create.${payload.buyOrSell}.success.title`,
      bodyKey: `input-mask.merchant-modification.merchant-code-create.${payload.buyOrSell}.success.body`,
    },
    payload,
  } as const)
export const createMerchantCodeErrorAction = (e: AxiosError) =>
  ({
    type: MerchantModificationActionsEnum.CREATE_MERCHANT_CODE_ERROR,
  } as const)

export const createLegalEntityAttemptAction = () =>
  ({
    type: MerchantModificationActionsEnum.CREATE_LEGAL_ENTITY_ATTEMPT,
  } as const)

export const createLegalEntitySuccessAction = (payload: {
  legalEntity: LegalEntity
}) =>
  ({
    type: MerchantModificationActionsEnum.CREATE_LEGAL_ENTITY_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `input-mask.merchant-modification.legal-entity-create.success.title`,
      bodyKey: `input-mask.merchant-modification.legal-entity-create.success.body`,
    },
    payload,
  } as const)
export const createLegalEntityErrorAction = (e: AxiosError) =>
  ({
    type: MerchantModificationActionsEnum.CREATE_LEGAL_ENTITY_ERROR,
  } as const)

export const createMerchantAttemptAction = () =>
  ({
    type: MerchantModificationActionsEnum.CREATE_MERCHANT_ATTEMPT,
  } as const)

export const createMerchantSuccessAction = (payload: {
  buyOrSell: buyOrSell
  legalEntity: LegalEntity
}) =>
  ({
    type: MerchantModificationActionsEnum.CREATE_MERCHANT_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `input-mask.merchant-modification.merchant-create.${payload.buyOrSell}.success.title`,
      bodyKey: `input-mask.merchant-modification.merchant-create.${payload.buyOrSell}.success.body`,
    },
    payload,
  } as const)
export const createMerchantErrorAction = (e: AxiosError) =>
  ({
    type: MerchantModificationActionsEnum.CREATE_MERCHANT_ERROR,
  } as const)

export const setInitialLegalEntityAction = (payload: LegalEntity) =>
  ({
    type: MerchantModificationActionsEnum.SET_INITIAL_LEGAL_ENTITY,
    payload,
  } as const)

export const setModifyLegalEntityForOtherInvoices = (payload: boolean | null) =>
  ({
    type: MerchantModificationActionsEnum.SET_MODIFY_OTHER_INVOICES_LEGAL_ENTITY,
    payload,
  } as const)

export const selectLegalEntityAction = (payload: LegalEntity) =>
  ({
    type: MerchantModificationActionsEnum.SELECT_LEGAL_ENTITY,
    payload,
  } as const)

export const selectMerchantAction = (payload: LegalEntity) =>
  ({
    type: MerchantModificationActionsEnum.SELECT_MERCHANT,
    payload,
  } as const)

export const selectMerchantCodeAction = (payload: LegalEntity) =>
  ({
    type: MerchantModificationActionsEnum.SELECT_MERCHANT_CODE,
    payload,
  } as const)

export const keydownMerchantCodeAction = (payload: string) =>
  ({
    type: MerchantModificationActionsEnum.KEYDOWN_MERCHANT_CODE,
    payload,
  } as const)
export const keydownLegalEntityAction = (payload: string) =>
  ({
    type: MerchantModificationActionsEnum.KEYDOWN_LEGAL_ENTITY,
    payload,
  } as const)

export const keydownMerchantAction = (payload: string) =>
  ({
    type: MerchantModificationActionsEnum.KEYDOWN_MERCHANT,
    payload,
  } as const)

export const resetMerchantModification = () =>
  ({
    type: MerchantModificationActionsEnum.RESET_MERCHANT_MODIFICATION,
  } as const)
export const reinitializeMerchantModification = () =>
  ({
    type: MerchantModificationActionsEnum.REINITIALIZE_MERCHANT_MODIFICATION,
  } as const)

export const merchantModificationInitialState: MerchantModificationState = {
  identificationNumbers: [],
  identificationNumbersLoadingStatus: "loading",
  merchantModificationLoadingStatus: "idle",
  merchantModificationInfo: null,
  initialMerchant: {
    legalEntity: null,
  },
  editedMerchant: {
    legalEntity: null,
  },
  modifyLegalEntityForOtherInvoices: null,
  merchantCodeToCreate: "",
  merchantToCreate: "",
  legalEntityToCreate: "",
}

export interface MerchantModificationState {
  identificationNumbers: FullDocumentIdentificationNumber[]
  identificationNumbersLoadingStatus: "loading" | "finished"
  merchantModificationLoadingStatus: "idle" | "loading" | "error" | "success"
  merchantModificationInfo: MerchantModificationInfo | null
  initialMerchant: {
    legalEntity: LegalEntity | null
  }
  editedMerchant: {
    legalEntity: LegalEntity | null
  }
  modifyLegalEntityForOtherInvoices: boolean | null
  merchantCodeToCreate: string
  merchantToCreate: string
  legalEntityToCreate: string
}

export function merchantModificationReducer(
  state = merchantModificationInitialState,
  action: MerchantModificationActionsType
): MerchantModificationState {
  switch (action.type) {
    case MerchantModificationActionsEnum.GET_IDENTIFICATION_NUMBERS_ATTEMPT:
      return {
        ...state,
        identificationNumbers: [],
        identificationNumbersLoadingStatus: "loading",
      }

    case MerchantModificationActionsEnum.GET_IDENTIFICATION_NUMBERS_SUCCESS:
      return {
        ...state,
        identificationNumbers: action.payload,
        identificationNumbersLoadingStatus: "finished",
      }

    case MerchantModificationActionsEnum.GET_IDENTIFICATION_NUMBERS_ERROR:
      return {
        ...state,
        identificationNumbers: [],
        identificationNumbersLoadingStatus: "finished",
      }

    case MerchantModificationActionsEnum.GET_MERCHANT_MODIFICATION_INFO_ATTEMPT:
      return {
        ...state,
        merchantModificationLoadingStatus: "loading",
      }

    case MerchantModificationActionsEnum.GET_MERCHANT_MODIFICATION_INFO_ERROR:
      return {
        ...state,
        merchantModificationLoadingStatus: "error",
      }

    case MerchantModificationActionsEnum.GET_MERCHANT_MODIFICATION_INFO_SUCCESS:
      return {
        ...state,
        merchantModificationLoadingStatus: "success",
        merchantModificationInfo: action.payload,
      }

    case MerchantModificationActionsEnum.SELECT_LEGAL_ENTITY:
      return {
        ...state,
        editedMerchant: {
          ...state.editedMerchant,
          legalEntity: action.payload,
        },
        merchantCodeToCreate: "",
        merchantToCreate: "",
        legalEntityToCreate: "",
      }

    case MerchantModificationActionsEnum.UPDATE_LEGAL_ENTITY_ATTEMPT:
      return { ...state, merchantModificationLoadingStatus: "loading" }

    case MerchantModificationActionsEnum.UPDATE_LEGAL_ENTITY_SUCCESS:
      return { ...state, merchantModificationLoadingStatus: "success" }

    case MerchantModificationActionsEnum.UPDATE_LEGAL_ENTITY_ERROR:
      return { ...state, merchantModificationLoadingStatus: "error" }

    case MerchantModificationActionsEnum.UPDATE_MERCHANT_ATTEMPT:
      return { ...state, merchantModificationLoadingStatus: "loading" }
    case MerchantModificationActionsEnum.UPDATE_MERCHANT_ERROR:
      return { ...state, merchantModificationLoadingStatus: "error" }
    case MerchantModificationActionsEnum.UPDATE_MERCHANT_SUCCESS:
      return {
        ...state,
        merchantModificationLoadingStatus: "success",
        initialMerchant: {
          ...state.editedMerchant,
          legalEntity: action.payload.legalEntity,
        },
        editedMerchant: {
          ...state.editedMerchant,
          legalEntity: action.payload.legalEntity,
        },
      }

    case MerchantModificationActionsEnum.UPDATE_MERCHANT_CODE_ATTEMPT:
      return { ...state, merchantModificationLoadingStatus: "loading" }

    case MerchantModificationActionsEnum.UPDATE_MERCHANT_CODE_SUCCESS:
      return {
        ...state,
        merchantModificationLoadingStatus: "success",
        initialMerchant: {
          ...state.editedMerchant,
          legalEntity: action.payload.legalEntity,
        },
        editedMerchant: {
          ...state.editedMerchant,
          legalEntity: action.payload.legalEntity,
        },
      }

    case MerchantModificationActionsEnum.UPDATE_MERCHANT_CODE_ERROR:
      return { ...state, merchantModificationLoadingStatus: "error" }

    case MerchantModificationActionsEnum.CREATE_LEGAL_ENTITY_ATTEMPT:
      return { ...state, merchantModificationLoadingStatus: "loading" }

    case MerchantModificationActionsEnum.CREATE_LEGAL_ENTITY_ERROR:
      return { ...state, merchantModificationLoadingStatus: "error" }

    case MerchantModificationActionsEnum.CREATE_LEGAL_ENTITY_SUCCESS:
      return {
        ...state,
        merchantModificationLoadingStatus: "success",
        initialMerchant: {
          ...state.editedMerchant,
          legalEntity: action.payload.legalEntity,
        },
        editedMerchant: {
          ...state.editedMerchant,
          legalEntity: action.payload.legalEntity,
        },
        merchantCodeToCreate: "",
        merchantToCreate: "",
      }

    case MerchantModificationActionsEnum.CREATE_MERCHANT_CODE_ATTEMPT:
      return { ...state, merchantModificationLoadingStatus: "loading" }
    case MerchantModificationActionsEnum.CREATE_MERCHANT_CODE_SUCCESS:
      return {
        ...state,
        merchantModificationLoadingStatus: "success",
        initialMerchant: {
          ...state.editedMerchant,
          legalEntity: action.payload.legalEntity,
        },
        editedMerchant: {
          ...state.editedMerchant,
          legalEntity: action.payload.legalEntity,
        },
        merchantCodeToCreate: "",
        merchantToCreate: "",
      }
    case MerchantModificationActionsEnum.CREATE_MERCHANT_CODE_ERROR:
      return { ...state, merchantModificationLoadingStatus: "error" }

    case MerchantModificationActionsEnum.CREATE_MERCHANT_ATTEMPT:
      return { ...state, merchantModificationLoadingStatus: "loading" }
    case MerchantModificationActionsEnum.CREATE_MERCHANT_ERROR:
      return { ...state, merchantModificationLoadingStatus: "error" }
    case MerchantModificationActionsEnum.CREATE_MERCHANT_SUCCESS:
      return {
        ...state,
        merchantModificationLoadingStatus: "success",
        initialMerchant: {
          ...state.editedMerchant,
          legalEntity: action.payload.legalEntity,
        },
        editedMerchant: {
          ...state.editedMerchant,
          legalEntity: action.payload.legalEntity,
        },
        merchantCodeToCreate: "",
        merchantToCreate: "",
      }

    case MerchantModificationActionsEnum.SELECT_MERCHANT:
      return {
        ...state,
        editedMerchant: {
          ...state.editedMerchant,
          legalEntity: action.payload,
        },
        merchantCodeToCreate: "",
      }

    case MerchantModificationActionsEnum.SELECT_MERCHANT_CODE:
      return {
        ...state,
        editedMerchant: {
          ...state.editedMerchant,
          legalEntity: action.payload,
        },
        merchantCodeToCreate: "",
      }

    case MerchantModificationActionsEnum.KEYDOWN_MERCHANT_CODE:
      if (!state.editedMerchant.legalEntity) {
        return { ...state }
      }

      return {
        ...state,
        merchantCodeToCreate: action.payload,
        editedMerchant: {
          ...state.editedMerchant,
          legalEntity: {
            ...state.editedMerchant.legalEntity,
            merchant_code_id: 0,
          },
        },
      }

    case MerchantModificationActionsEnum.KEYDOWN_LEGAL_ENTITY:
      if (!state.editedMerchant.legalEntity) {
        return { ...state }
      }

      return {
        ...state,
        legalEntityToCreate: action.payload,
        editedMerchant: {
          ...state.editedMerchant,
          legalEntity: {
            ...state.editedMerchant.legalEntity,
            id: 0,
          },
        },
      }

    case MerchantModificationActionsEnum.KEYDOWN_MERCHANT:
      if (!state.editedMerchant.legalEntity) {
        return { ...state }
      }

      return {
        ...state,
        merchantToCreate: action.payload,
        editedMerchant: {
          ...state.editedMerchant,
          legalEntity: {
            ...state.editedMerchant.legalEntity,
            merchant_id: 0,
            merchant_code_id: 0,
          },
        },
      }

    case MerchantModificationActionsEnum.SET_INITIAL_LEGAL_ENTITY:
      return {
        ...state,
        initialMerchant: {
          ...state.editedMerchant,
          legalEntity: action.payload,
        },
      }

    case MerchantModificationActionsEnum.SET_MODIFY_OTHER_INVOICES_LEGAL_ENTITY:
      return {
        ...state,
        modifyLegalEntityForOtherInvoices: action.payload,
      }

    case MerchantModificationActionsEnum.RESET_MERCHANT_MODIFICATION:
      return {
        ...state,
        editedMerchant: { ...state.initialMerchant },
        modifyLegalEntityForOtherInvoices: null,
        legalEntityToCreate: "",
        merchantToCreate: "",
        merchantCodeToCreate: "",
      }

    case MerchantModificationActionsEnum.REINITIALIZE_MERCHANT_MODIFICATION:
      return merchantModificationInitialState

    default:
      return { ...state }
  }
}

interface FullDocumentIdentificationNumber {
  legal_entity_id: string
  legal_entity_name: string
  identification_number: string
  merchant_id: number | null
}

export const getInputMaskIdentificationNumbersThunk =
  ({ fullDocumentId }: { fullDocumentId: number }) =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch(getIdentificationNumbersAttemptAction())
    return axios
      .get<FullDocumentIdentificationNumber[]>(
        `/full_documents/${fullDocumentId}/input_mask_get_identification_numbers`
      )
      .then(({ data }) => {
        dispatch(getIdentificationNumbersSuccessAction(data))
      })
      .catch((error: AxiosError) => {
        dispatch(getIdentificationNumbersErrorAction(error))
      })
  }

export interface LegalEntity {
  id: number
  name: string
  identification_number: string
  merchant_id: number
  merchant_name: string
  merchant_code_id: number
  merchant_code: string
}

interface MerchantModificationInfo {
  current_full_document_reference: string
  impacted_documents_references: string[]
  legal_entities: LegalEntity[]
}
export const getMerchantModificationInfoThunk =
  ({ fullDocumentId }: { fullDocumentId: number }) =>
  (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    dispatch(getMerchantModificationInfoAttemptAction())
    return axios
      .get<MerchantModificationInfo>(
        `/full_documents/${fullDocumentId}/input_mask_get_merchant_modification_info`
      )
      .then(({ data }) => {
        dispatch(getMerchantModificationInfoSuccessAction(data))
        const legalEntityId =
          getState().inputMask.initial_full_document?.legal_entity_id

        const legalEntities = data.legal_entities
        const legalEntityToSelect = legalEntities.find(
          (le) => le.id === legalEntityId
        )
        if (legalEntityToSelect) {
          dispatch(setInitialLegalEntityAction(legalEntityToSelect))
          dispatch(selectLegalEntityAction(legalEntityToSelect))
        }
      })
      .catch((error: AxiosError) => {
        dispatch(getMerchantModificationInfoErrorAction(error))
      })
  }

export const updateLegalEntityThunk =
  () =>
  (
    dispatch: Dispatch<AnyAction | RNBThunkAction>,
    getState: () => RootState
  ) => {
    const fullDocumentId = getState().inputMask.initial_full_document?.fd_id
    const newLegalEntity =
      getState().merchantModification.editedMerchant.legalEntity
    const modifyLegalEntityForOtherInvoices = Boolean(
      getState().merchantModification.modifyLegalEntityForOtherInvoices
    )

    dispatch(updateLegalEntityAttemptAction())
    return axios
      .put(`/full_documents/${fullDocumentId}/input_mask_update_legal_entity`, {
        new_legal_entity_id: newLegalEntity?.id,
        modify_legal_entity_for_other_invoices:
          modifyLegalEntityForOtherInvoices,
      })
      .then(() => {
        dispatch(
          updateLegalEntitySuccessAction({
            modifyLegalEntityForOtherInvoices:
              modifyLegalEntityForOtherInvoices,
          })
        )
        if (newLegalEntity) {
          dispatch(changeLegalEntity({ legalEntity: newLegalEntity }))
        }
        if (fullDocumentId) {
          // refresh references
          dispatch(
            getMerchantModificationInfoThunk({ fullDocumentId: fullDocumentId })
          )
        }
      })
      .catch((error: AxiosError) => {
        dispatch(updateLegalEntityErrorAction(error))
      })
  }

export const updateMerchantThunk =
  () =>
  (
    dispatch: Dispatch<AnyAction | RNBThunkAction>,
    getState: () => RootState
  ) => {
    const fullDocumentId = getState().inputMask.initial_full_document?.fd_id
    const buyOrSell =
      getState().inputMask.edited_full_document?.buy_or_sell || "buy"
    const newMerchantId =
      getState().merchantModification.editedMerchant.legalEntity?.merchant_id

    dispatch(updateMerchantAttemptAction())
    return axios
      .put<LegalEntity>(
        `/full_documents/${fullDocumentId}/input_mask_update_merchant`,
        {
          new_merchant_id: newMerchantId,
        }
      )
      .then((response) => {
        dispatch(
          updateMerchantSuccessAction({ buyOrSell, legalEntity: response.data })
        )

        dispatch(
          changeMerchant({
            merchantId: response.data.merchant_id,
            merchantName: response.data.merchant_name,
            merchantCode: response.data.merchant_code,
            merchantCodeId: response.data.merchant_code_id,
          })
        )
      })
      .catch((error: AxiosError) => {
        dispatch(updateMerchantErrorAction(error))
      })
  }

export const updateMerchantCodeThunk =
  () =>
  (
    dispatch: Dispatch<AnyAction | RNBThunkAction>,
    getState: () => RootState
  ) => {
    const fullDocumentId = getState().inputMask.initial_full_document?.fd_id
    const buyOrSell =
      getState().inputMask.edited_full_document?.buy_or_sell || "buy"
    const newMerchantId =
      getState().merchantModification.editedMerchant.legalEntity
        ?.merchant_code_id

    dispatch(updateMerchantCodeAttemptAction())
    return axios
      .put<LegalEntity>(
        `/full_documents/${fullDocumentId}/input_mask_update_merchant_code`,
        {
          new_merchant_code_id: newMerchantId,
        }
      )
      .then((response) => {
        dispatch(
          updateMerchantCodeSuccessAction({
            buyOrSell,
            legalEntity: response.data,
          })
        )

        dispatch(
          changeMerchant({
            merchantId: response.data.merchant_id,
            merchantName: response.data.merchant_name,
            merchantCode: response.data.merchant_code,
            merchantCodeId: response.data.merchant_code_id,
          })
        )
      })
      .catch((error: AxiosError) => {
        dispatch(updateMerchantCodeErrorAction(error))
      })
  }

export const createMerchantCodeThunk =
  () =>
  (
    dispatch: Dispatch<AnyAction | RNBThunkAction>,
    getState: () => RootState
  ) => {
    const fullDocumentId = getState().inputMask.initial_full_document?.fd_id
    const companyId = getState().inputMask.companyId
    const buyOrSell =
      getState().inputMask.edited_full_document?.buy_or_sell || "buy"
    const merchantCodeToCreate =
      getState().merchantModification.merchantCodeToCreate

    dispatch(createMerchantCodeAttemptAction())

    if (!companyId) {
      return
    }

    return axios
      .put<LegalEntity>(
        `/full_documents/${fullDocumentId}/input_mask_create_merchant_code`,
        {
          new_merchant_code: merchantCodeToCreate,
        }
      )
      .then((response) => {
        dispatch(
          createMerchantCodeSuccessAction({
            buyOrSell,
            legalEntity: response.data,
          })
        )

        dispatch(
          changeMerchant({
            merchantId: response.data.merchant_id,
            merchantName: response.data.merchant_name,
            merchantCode: response.data.merchant_code,
            merchantCodeId: response.data.merchant_code_id,
          })
        )
        dispatch(GetCompanyMerchantCodesDataThunk(companyId))
        dispatch(GetAllMerchantsOfCompanyForCodes(companyId))
      })
      .catch((error: AxiosError) => {
        dispatch(createMerchantCodeErrorAction(error))
      })
  }

export const createMerchantThunk =
  () =>
  (
    dispatch: Dispatch<AnyAction | RNBThunkAction>,
    getState: () => RootState
  ) => {
    const state = getState()
    const fullDocumentId = state.inputMask.initial_full_document?.fd_id
    const companyId = state.inputMask.companyId
    const buyOrSell = state.inputMask.edited_full_document?.buy_or_sell || "buy"
    const merchantCodeToCreate = state.merchantModification.merchantCodeToCreate
    const merchantToCreate = state.merchantModification.merchantToCreate

    const selectedMerchantCodeId =
      state.merchantModification.editedMerchant?.legalEntity?.merchant_code_id

    dispatch(createMerchantAttemptAction())

    if (!companyId) {
      return
    }

    return axios
      .put<LegalEntity>(
        `/full_documents/${fullDocumentId}/input_mask_create_merchant`,
        {
          new_merchant_code: merchantCodeToCreate,
          selected_merchant_code_id: selectedMerchantCodeId,
          new_merchant_name: merchantToCreate,
        }
      )
      .then((response) => {
        dispatch(
          createMerchantSuccessAction({
            buyOrSell,
            legalEntity: response.data,
          })
        )

        dispatch(
          changeMerchant({
            merchantId: response.data.merchant_id,
            merchantName: response.data.merchant_name,
            merchantCode: response.data.merchant_code,
            merchantCodeId: response.data.merchant_code_id,
          })
        )
        dispatch(GetCompanyMerchantCodesDataThunk(companyId))
        dispatch(GetAllMerchantsOfCompanyForCodes(companyId))
      })
      .catch((error: AxiosError) => {
        dispatch(createMerchantErrorAction(error))
      })
  }

export const createLegalEntityThunk =
  () =>
  (
    dispatch: Dispatch<AnyAction | RNBThunkAction>,
    getState: () => RootState
  ) => {
    const state = getState()
    const companyId = state.inputMask.companyId
    const fullDocumentId = state.inputMask.initial_full_document?.fd_id
    const modify_legal_entity_for_other_invoices = Boolean(
      state.merchantModification.modifyLegalEntityForOtherInvoices
    )
    const new_legal_entity_name = state.merchantModification.legalEntityToCreate
    const new_merchant_name = state.merchantModification.merchantToCreate
    const selected_merchant_code_id =
      state.merchantModification.editedMerchant.legalEntity?.merchant_code_id
    const new_merchant_code = state.merchantModification.merchantCodeToCreate

    dispatch(createLegalEntityAttemptAction())

    if (!companyId) {
      return
    }
    if (!fullDocumentId) {
      return
    }

    return axios
      .put<LegalEntity>(
        `/full_documents/${fullDocumentId}/input_mask_create_legal_entity`,
        {
          new_legal_entity_name,
          new_merchant_name,
          selected_merchant_code_id,
          new_merchant_code,
          modify_legal_entity_for_other_invoices,
        }
      )
      .then((response) => {
        dispatch(
          createLegalEntitySuccessAction({
            legalEntity: response.data,
          })
        )
        dispatch(
          changeMerchant({
            merchantId: response.data.merchant_id,
            merchantName: response.data.merchant_name,
            merchantCode: response.data.merchant_code,
            merchantCodeId: response.data.merchant_code_id,
          })
        )

        dispatch(GetCompanyMerchantCodesDataThunk(companyId))
        dispatch(GetAllMerchantsOfCompanyForCodes(companyId))

        const updatedLegalEntity =
          getState().merchantModification.editedMerchant.legalEntity

        if (updatedLegalEntity) {
          dispatch(changeLegalEntity({ legalEntity: updatedLegalEntity }))
        }

        dispatch(
          getMerchantModificationInfoThunk({
            fullDocumentId: fullDocumentId,
          })
        )
      })
      .catch((error: AxiosError) => {
        dispatch(createLegalEntityErrorAction(error))
      })
  }
