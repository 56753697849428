import styled from "styled-components/macro"
import { useDispatch } from "react-redux"

import { changeGoodsOrServices } from "../../store/ducks/inputMask.ducks"
import { useRNBSelector } from "../../store/rootReducer"
import { Select } from "../Commons/Select"
import { GoodsOrServices } from "../../store/ducks/fullDocuments.ducks"
import { useEffect, useState } from "react"

export const GoodsOrServicesSelectCell = () => {
  const dispatch = useDispatch()

  const { editedFullDocument } = useRNBSelector((state) => ({
    editedFullDocument: state.inputMask.edited_full_document,
  }))

  const goodsOrServicesOptions = [
    { label: "Biens", value: "goods" },
    { label: "Services", value: "services" },
    { label: "Consommation sur place", value: "on_site_consumption" },
  ]

  const emptyOption = { label: "", value: "" }

  const getSelectedOption = () =>
    goodsOrServicesOptions.find(
      (option) => option.value === editedFullDocument?.goods_or_services
    ) || emptyOption

  const [goodsOrServicesOptionForSelect, setGoodsOrServicesOptionForSelect] =
    useState(goodsOrServicesOptions)
  const [selectedOption, setSelectedOption] = useState(getSelectedOption)
  const [sourceCellLocked, setSourceCellLocked] = useState(false)

  useEffect(() => {
    if (editedFullDocument?.document_source === "fr") {
      const filteredOptions = goodsOrServicesOptions.filter(
        (option) => option.value !== "on_site_consumption"
      )
      setGoodsOrServicesOptionForSelect(filteredOptions)
    } else setGoodsOrServicesOptionForSelect(goodsOrServicesOptions)
  }, [editedFullDocument?.document_source])

  useEffect(() => {
    if (editedFullDocument?.document_type === "bill_of_exchange") {
      dispatch(changeGoodsOrServices("services"))
      setSelectedOption({ label: "Services", value: "services " })
      setSourceCellLocked(true)
    } else setSourceCellLocked(false)
  }, [editedFullDocument?.document_type])

  return (
    <StyledSelect
      height="8rem"
      intl={null}
      value={selectedOption}
      defaultValue={null}
      options={goodsOrServicesOptionForSelect}
      onChangeCallback={({ value }) => {
        if (!editedFullDocument) return
        dispatch(changeGoodsOrServices(value as GoodsOrServices))
        setSelectedOption(
          goodsOrServicesOptionForSelect.find(
            (option) => option.value === value
          )!
        )
      }}
      border="none"
      borderRadius="0"
      centerLabel={true}
      locked={sourceCellLocked}
      disabled={sourceCellLocked}
    />
  )
}

const StyledSelect = styled(Select)`
  width: 100%;
`
