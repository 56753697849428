/* eslint-disable camelcase */
import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"

import { useRNBSelector } from "../../../store/rootReducer"
import { colors } from "../../../styles/design.config"
import { Select } from "../../Commons/Select"
import {
  keydownMerchantAction,
  selectMerchantAction,
} from "../../../store/ducks/merchantModification.ducks"
import { Text } from "../../Commons/Text"
import { capitalizeFirstLetter, normalizeString } from "../../../utils/string"
import { normalizeMerchantCode } from "../../../utils/merchantCodes"
import styled from "styled-components/macro"
import { Alert } from "../../Commons/Alert"

export interface MerchantsOption {
  value: string
  label: string
}

export const MerchantSelect = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const {
    merchantsOfCompany,
    legalEntity,
    initialLegalEntity,
    merchantToCreate,
    merchantCodeToCreate,
    buyOrSell,
    legalEntityToCreate,
  } = useRNBSelector((state) => ({
    merchantsOfCompany: state.merchantCodes.merchantsOfCompany,
    legalEntity: state.merchantModification.editedMerchant.legalEntity,
    initialLegalEntity: state.merchantModification.initialMerchant.legalEntity,
    merchantToCreate: state.merchantModification.merchantToCreate,
    merchantCodeToCreate: state.merchantModification.merchantCodeToCreate,
    legalEntityToCreate: state.merchantModification.legalEntityToCreate,
    buyOrSell: state.inputMask.initial_full_document?.buy_or_sell || "buy",
  }))

  const legalEntityWithoutSiren = !legalEntity?.identification_number

  const merchantOptionsFromDB = merchantsOfCompany.map((m) => {
    return {
      value: String(m.id),
      label: capitalizeFirstLetter(m.name),
    }
  })

  const merchantOptions = merchantToCreate
    ? [
        ...merchantOptionsFromDB,
        { value: "0", label: `${merchantToCreate} - création` },
      ]
    : merchantOptionsFromDB

  const lockSelect =
    legalEntityWithoutSiren ||
    initialLegalEntity?.id !== legalEntity?.id ||
    (initialLegalEntity?.merchant_id === legalEntity.merchant_id &&
      initialLegalEntity?.merchant_code_id !== legalEntity?.merchant_code_id)

  return (
    <>
      <SelectWrapper>
        <Select
          intl={intl}
          locked={lockSelect}
          disabled={lockSelect}
          options={merchantOptions}
          value={
            merchantOptions.find(
              (m) => Number(m.value) === legalEntity?.merchant_id
            ) || null
          }
          defaultValue={null}
          onChangeCallback={(e: MerchantsOption) => {
            if (!legalEntity) {
              return
            }

            const selectedMerchant = merchantsOfCompany.find(
              (m) => m.id.toString() === e.value
            )

            if (!selectedMerchant?.id) return
            if (!selectedMerchant?.code_id) return

            dispatch(
              selectMerchantAction({
                ...legalEntity,
                merchant_id: selectedMerchant.id,
                merchant_code_id: selectedMerchant.code_id || 0,
              })
            )
          }}
          onInputChangeCallback={(e) => {
            if (!legalEntity) {
              return
            }

            if (!e) return
            if (legalEntityWithoutSiren) {
              return
            }

            const selectedMerchant = merchantsOfCompany.find(
              (m) => normalizeMerchantCode(m.name) === normalizeMerchantCode(e)
            )

            if (selectedMerchant) {
              dispatch(
                selectMerchantAction({
                  ...legalEntity,
                  merchant_id: selectedMerchant.id,
                  merchant_code_id: selectedMerchant.code_id || 0,
                })
              )
            } else {
              dispatch(keydownMerchantAction(normalizeString(e)))
            }
          }}
          label={""}
          domain={""}
          optionType={""}
          borderRadius={"0"}
          customWidth={"30rem"}
          backgroundColor={colors.white}
          overrideBorderColorStr={"transparent"}
          height="10rem"
        />
      </SelectWrapper>
      {!legalEntityToCreate && legalEntityWithoutSiren && (
        <>
          <Text
            text={intl.formatMessage({
              id: `input-mask.merchant-modification.merchant.blocked-by-identification-number`,
            })}
          />
          <Ct.Spacer />
        </>
      )}
      {legalEntityToCreate && (
        <>
          <Text
            text={intl.formatMessage({
              id: `input-mask.merchant-modification.merchant.${buyOrSell}.creating-legal-entity`,
            })}
          />
          <Ct.Spacer />
        </>
      )}
      {!legalEntityToCreate &&
        merchantToCreate &&
        !merchantCodeToCreate &&
        legalEntity?.merchant_code_id === 0 && (
          <>
            <StyledAlertWrapper>
              <Alert alertType={"bulb"}>
                <Text
                  text={intl.formatMessage({
                    id: `input-mask.merchant-modification.merchant.${buyOrSell}.need-to-select-code`,
                  })}
                />
              </Alert>
            </StyledAlertWrapper>

            <Ct.Spacer />
          </>
        )}
    </>
  )
}

const SelectWrapper = styled.div`
  align-self: center;
`
const StyledAlertWrapper = styled.div`
  margin: 0 2rem;
`
