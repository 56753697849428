/* eslint-disable camelcase */
import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"

import { useRNBSelector } from "../../../store/rootReducer"
import { colors } from "../../../styles/design.config"
import { Select } from "../../Commons/Select"
import { Text } from "../../Commons/Text"
import {
  keydownMerchantCodeAction,
  selectMerchantCodeAction,
} from "../../../store/ducks/merchantModification.ducks"
import { toggleBlockedByAlreadyTakenMerchantCode } from "../../../store/ducks/inputMask.ducks"
import { getIdFromParams } from "../../../utils/company"
import { useParams } from "react-router-dom"
import { Switch } from "../../Commons/Switch"
import styled from "styled-components/macro"
import { normalizeMerchantCode } from "../../../utils/merchantCodes"
import { getLockMerchantCode } from "../../../utils/merchantModification"

export interface MerchantCodesOption {
  value: string
  label: string
}

export const MerchantCodeSelect = () => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const {
    merchantCodes,
    blockedByAlreadyTakenMerchantCode,
    legalEntity,
    initialLegalEntity,
    disableCentralizeCode,
    editedFullDocument,
    hasChangedMerchantCode,
    hasChangedMerchant,
    legalEntityToCreate,
    merchantCodeToCreate,
    merchantToCreate,
  } = useRNBSelector((state) => ({
    merchantCodes: state.merchantCodes.merchantCodes,
    blockedByAlreadyTakenMerchantCode:
      state.inputMask.blockedByAlreadyTakenMerchantCode,
    legalEntity: state.merchantModification.editedMerchant.legalEntity,
    initialLegalEntity: state.merchantModification.initialMerchant.legalEntity,
    disableCentralizeCode:
      state.companies.companies[selectedCompanyId].disable_centralized_codes,
    editedFullDocument: state.inputMask.edited_full_document,
    hasChangedMerchantCode:
      state.merchantModification.editedMerchant.legalEntity
        ?.merchant_code_id !==
      state.merchantModification.initialMerchant.legalEntity?.merchant_code_id,
    hasChangedMerchant:
      state.merchantModification.editedMerchant.legalEntity?.merchant_id !==
      state.merchantModification.initialMerchant.legalEntity?.merchant_id,
    legalEntityToCreate: state.merchantModification.legalEntityToCreate,
    merchantCodeToCreate: state.merchantModification.merchantCodeToCreate,
    merchantToCreate: state.merchantModification.merchantToCreate,
  }))

  const merchantCodeOptionsFromDB = merchantCodes.map((mc) => {
    return {
      value: String(mc.id),
      label: mc.code,
    }
  })

  const merchantCodesOptions = merchantCodeToCreate
    ? [
        ...merchantCodeOptionsFromDB,
        { value: "0", label: merchantCodeToCreate },
      ]
    : merchantCodeOptionsFromDB

  const alreadyTakenMerchantCode = merchantCodes.find(
    (m) =>
      m.id !== initialLegalEntity?.merchant_code_id &&
      m.id === legalEntity?.merchant_code_id &&
      m.merchants.length > 0 &&
      m.centralize === false
  )

  const updateBlockedWhenTaken = (merchantCodeId: number) => {
    if (
      merchantCodes.find(
        (m) =>
          m.id === merchantCodeId &&
          m.id !== initialLegalEntity?.merchant_code_id &&
          m.merchants.length > 0 &&
          m.centralize === false
      )
    ) {
      dispatch(
        toggleBlockedByAlreadyTakenMerchantCode({
          blockedByAlreadyTakenMerchantCode: true,
        })
      )
    } else {
      dispatch(
        toggleBlockedByAlreadyTakenMerchantCode({
          blockedByAlreadyTakenMerchantCode: false,
        })
      )
    }
  }

  const lockMerchantCode = getLockMerchantCode({
    legalEntityToCreate,
    merchantToCreate,
    hasChangedMerchant,
  })

  return (
    <>
      <SelectWrapper>
        <Select
          intl={intl}
          locked={lockMerchantCode}
          disabled={lockMerchantCode}
          options={merchantCodesOptions}
          value={
            merchantCodesOptions.find(
              (m) => Number(m.value) === legalEntity?.merchant_code_id
            ) || null
          }
          defaultValue={null}
          onChangeCallback={(e: MerchantCodesOption) => {
            if (!legalEntity) {
              return
            }

            const merchantCodeId = Number(e.value)
            const selectedMerchantCode = merchantCodes.find(
              (mc) => mc.id === merchantCodeId
            )

            if (!selectedMerchantCode?.id) return

            updateBlockedWhenTaken(merchantCodeId)

            dispatch(
              selectMerchantCodeAction({
                ...legalEntity,
                merchant_code_id: selectedMerchantCode.id,
              })
            )
          }}
          onInputChangeCallback={(e) => {
            if (!e) return
            if (!legalEntity) {
              return
            }

            const selectedMerchantCode = merchantCodes.find(
              (mc) => mc.code === normalizeMerchantCode(e)
            )

            if (selectedMerchantCode) {
              dispatch(
                selectMerchantCodeAction({
                  ...legalEntity,
                  merchant_code_id: selectedMerchantCode.id,
                })
              )
            } else {
              dispatch(keydownMerchantCodeAction(normalizeMerchantCode(e)))
            }
          }}
          label={""}
          domain={""}
          optionType={""}
          borderRadius={"0"}
          customWidth={"30rem"}
          backgroundColor={colors.white}
          overrideBorderColorStr={"transparent"}
          height="10rem"
        />
      </SelectWrapper>

      {(!hasChangedMerchant || merchantToCreate) &&
      (initialLegalEntity?.id === legalEntity?.id || legalEntityToCreate) &&
      Boolean(alreadyTakenMerchantCode) &&
      hasChangedMerchantCode ? (
        <>
          <Ct.Spacer />
          <Flex>
            {blockedByAlreadyTakenMerchantCode ? (
              <Text
                text={intl.formatMessage(
                  {
                    id: disableCentralizeCode
                      ? `input-mask.${editedFullDocument?.buy_or_sell}.already-taken-code.warning-and-centralize`
                      : `input-mask.${editedFullDocument?.buy_or_sell}.already-taken-code.warning`,
                  },
                  {
                    merchantNames: alreadyTakenMerchantCode?.merchants
                      .map((mc) => mc.merchant_name.toUpperCase())
                      .join(", "),
                  }
                )}
                textStyle={{
                  color: "amaranth",
                }}
              />
            ) : (
              <Text
                text={intl.formatMessage({
                  id: `input-mask.${editedFullDocument?.buy_or_sell}.already-taken-code.confirm`,
                })}
              />
            )}
            <Ct.Spacer />

            <SwitchWrapper>
              <Switch
                value={!blockedByAlreadyTakenMerchantCode}
                onToggle={() => {
                  dispatch(
                    toggleBlockedByAlreadyTakenMerchantCode({
                      blockedByAlreadyTakenMerchantCode:
                        !blockedByAlreadyTakenMerchantCode,
                    })
                  )
                }}
                onColor="shamrock"
                offColor="amaranth"
                transformDirection="reversed"
              />
            </SwitchWrapper>
            <Ct.Spacer />
          </Flex>
        </>
      ) : null}
    </>
  )
}

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const SwitchWrapper = styled.div``

const SelectWrapper = styled.div`
  align-self: center;
`
