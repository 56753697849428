import { AnyAction } from "redux"
import { RootState } from "../rootReducer"
import axios, { AxiosError } from "axios"
import { Dispatch, RNBThunkAction } from "../store.config"
import { buyOrSell } from "../../utils/company"
import { GetFiscalYearMerchantsThunk } from "./merchants.ducks"
import { updateFullDocumentNameSuccess } from "./batchDocuments.ducks"
import { CurrencyCode } from "../../utils/currencyUtils"
import { saveDocumentUrl } from "./writings.ducks"
import { GetDeactivatedUrlPreview } from "./companies.ducks"
import { DeactivatedReasonType } from "../../utils/translated_deactivated_reasons"
import { Document as DocumentInputMask } from "../../utils/inputMask"
import { GetTransactionsThunk } from "./bank.ducks"

export const enum FullDocumentsActionsEnum {
  FULL_DOCUMENT_UPDATE_ATTEMPT = "FULL_DOCUMENTS/fullDocumentUpdateAttempt",
  FULL_DOCUMENT_UPDATE_SUCCESS = "FULL_DOCUMENTS/fullDocumentUpdateSuccess",
  FULL_DOCUMENT_UPDATE_FAILURE = "FULL_DOCUMENTS/fullDocumentUpdateFailure",

  DEACTIVATE_FULL_DOCUMENT_RESET = "FULL_DOCUMENTS/deactivateFullDocumentReset",
  DEACTIVATE_FULL_DOCUMENT_ATTEMPT = "FULL_DOCUMENTS/deactivateFullDocumentAttempt",
  DEACTIVATE_FULL_DOCUMENT_SUCCESS = "FULL_DOCUMENTS/deactivateFullDocumentSuccess",
  DEACTIVATE_FULL_DOCUMENT_FAILURE = "FULL_DOCUMENTS/deactivateFullDocumentFailure",

  GET_DEACTIVATED_DOCUMENTS_ATTEMPT = "FULL_DOCUMENTS/deactivatedDocumentsAttempt",
  GET_DEACTIVATED_DOCUMENTS_SUCCESS = "FULL_DOCUMENTS/deactivatedDocumentsSuccess",
  GET_DEACTIVATED_DOCUMENTS_FAILURE = "FULL_DOCUMENTS/deactivatedDocumentsFailure",

  GET_FULL_DOCUMENTS_OF_WRITING_ATTEMPT = "FULL_DOCUMENTS/getFullDocumentOfWritingAttempt",
  GET_FULL_DOCUMENTS_OF_WRITING_SUCCESS = "FULL_DOCUMENTS/getFullDocumentOfWritingSuccess",
  GET_FULL_DOCUMENTS_OF_WRITING_FAILURE = "FULL_DOCUMENTS/getFullDocumentOfWritingFailure",

  GET_FULL_DOCUMENTS_OF_BATCH_ATTEMPT = "FULL_DOCUMENTS/getFullDocumentOfBatchAttempt",
  GET_FULL_DOCUMENTS_OF_BATCH_SUCCESS = "FULL_DOCUMENTS/getFullDocumentOfBatchSuccess",
  GET_FULL_DOCUMENTS_OF_BATCH_FAILURE = "FULL_DOCUMENTS/getFullDocumentOfBatchFailure",

  GET_PENDING_FULL_DOCUMENTS_ATTEMPT = "FULL_DOCUMENTS/getPendingFullDocumentsAttempt",
  GET_PENDING_FULL_DOCUMENTS_SUCCESS = "FULL_DOCUMENTS/getPendingFullDocumentsSuccess",
  GET_PENDING_FULL_DOCUMENTS_FAILURE = "FULL_DOCUMENTS/getPendingFullDocumentsFailure",

  RENAME_USER_FULL_DOCUMENTS_ATTEMPT = "FULL_DOCUMENTS/renameUserFullDocumentsAttempt",
  RENAME_USER_FULL_DOCUMENTS_FAILURE = "FULL_DOCUMENTS/renameUserFullDocumentsFailure",
  RENAME_USER_FULL_DOCUMENTS_SUCCESS = "FULL_DOCUMENTS/renameUserFullDocumentsSuccess",

  GET_DEACTIVATED_REASONS_ATTEMPT = "FULL_DOCUMENTS/getDeactivatedReasonsAttempt",
  GET_DEACTIVATED_REASONS_SUCCESS = "FULL_DOCUMENTS/getDeactivatedReasonsSuccess",
  GET_DEACTIVATED_REASONS_FAILURE = "FULL_DOCUMENTS/getDeactivatedReasonsFailure",

  GET_DOCUMENTS_FROM_FULL_DOCUMENT_ATTEMPT = "FULL_DOCUMENTS/getDocumentsFromFullDocumentSuccessAttempt",
  GET_DOCUMENTS_FROM_FULL_DOCUMENT_SUCCESS = "FULL_DOCUMENTS/getDocumentsFromFullDocumentSuccessSuccess",
  GET_DOCUMENTS_FROM_FULL_DOCUMENT_FAILURE = "FULL_DOCUMENTS/getDocumentsFromFullDocumentSuccessFailure",

  GET_URL_FULL_DOCUMENT_ATTEMPT = "FULL_DOCUMENTS/getUrlFullDocumentAttempt",
  GET_URL_FULL_DOCUMENT_SUCCESS = "FULL_DOCUMENTS/getUrlFullDocumentSuccess",
  GET_URL_FULL_DOCUMENT_FAILURE = "FULL_DOCUMENTS/getUrlFullDocumentFailure",

  GET_DOCUMENTS_TO_ATTACH_TO_TRANSACTIONS_ATTEMPT = "FULL_DOCUMENTS/GetDocumentsToAttachToTransactionsAttempt",
  GET_DOCUMENTS_TO_ATTACH_TO_TRANSACTIONS_SUCCESS = "FULL_DOCUMENTS/GetDocumentsToAttachToTransactionsSuccess",
  GET_DOCUMENTS_TO_ATTACH_TO_TRANSACTIONS_FAILURE = "FULL_DOCUMENTS/GetDocumentsToAttachToTransactionsFailure",
  GET_DOCUMENTS_TO_ATTACH_TO_TRANSACTIONS_RESET = "FULL_DOCUMENTS/GetDocumentsToAttachToTransactionsReset",

  ATTACH_FULL_DOCUMENT_TO_TRANSACTION_ATTEMPT = "FULL_DOCUMENTS/AttachFullDocumentToTransactionAttempt",
  ATTACH_FULL_DOCUMENT_TO_TRANSACTION_SUCCESS = "FULL_DOCUMENTS/AttachFullDocumentToTransactionSuccess",
  ATTACH_FULL_DOCUMENT_TO_TRANSACTION_FAILURE = "FULL_DOCUMENTS/AttachFullDocumentToTransactionFailure",
  ATTACH_FULL_DOCUMENT_TO_TRANSACTION_RESET = "FULL_DOCUMENTS/AttachFullDocumentToTransactionReset",

  DETACH_FULL_DOCUMENT_OF_TRANSACTION_ATTEMPT = "FULL_DOCUMENTS/DetachFullDocumentOfTransactionAttempt",
  DETACH_FULL_DOCUMENT_OF_TRANSACTION_SUCCESS = "FULL_DOCUMENTS/DetachFullDocumentOfTransactionSuccess",
  DETACH_FULL_DOCUMENT_OF_TRANSACTION_FAILURE = "FULL_DOCUMENTS/DetachFullDocumentOfTransactionFailure",

  GET_MERCHANT_FULL_DOCUMENTS_ARCHIVE_RESET = "FULL_DOCUMENTS/getMerchantFullDocumentsArchiveReset",
  GET_MERCHANT_FULL_DOCUMENTS_ARCHIVE_ATTEMPT = "FULL_DOCUMENTS/getMerchantFullDocumentsArchiveAttempt",
  GET_MERCHANT_FULL_DOCUMENTS_ARCHIVE_SUCCESS = "FULL_DOCUMENTS/getMerchantFullDocumentsArchiveSuccess",
  GET_MERCHANT_FULL_DOCUMENTS_ARCHIVE_FAILURE = "FULL_DOCUMENTS/getMerchantFullDocumentsArchiveFailure",
}

export const fullDocumentUpdateAttempt = () =>
  ({ type: FullDocumentsActionsEnum.FULL_DOCUMENT_UPDATE_ATTEMPT } as const)
export const fullDocumentUpdateSuccess = (payload: {
  documentId: number
  deactivatedBecause: string
}) =>
  ({
    type: FullDocumentsActionsEnum.FULL_DOCUMENT_UPDATE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey:
        "writing-lines.deactivated.table.change-deactivated.success.title",
      bodyKey:
        "writing-lines.deactivated.table.change-deactivated.success.body",
    },
    payload,
  } as const)
export const fullDocumentUpdateFailure = () =>
  ({
    type: FullDocumentsActionsEnum.FULL_DOCUMENT_UPDATE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey:
        "writing-lines.deactivated.table.change-deactivated.failure.title",
      bodyKey:
        "writing-lines.deactivated.table.change-deactivated.failure.body",
    },
  } as const)
export const deactivateFullDocumentReset = () =>
  ({ type: FullDocumentsActionsEnum.DEACTIVATE_FULL_DOCUMENT_RESET } as const)
export const deactivateFullDocumentAttempt = () =>
  ({ type: FullDocumentsActionsEnum.DEACTIVATE_FULL_DOCUMENT_ATTEMPT } as const)
export const deactivateFullDocumentSuccess = ({
  companyId,
  documentIds,
}: {
  companyId: number
  documentIds: number[]
}) =>
  ({
    type: FullDocumentsActionsEnum.DEACTIVATE_FULL_DOCUMENT_SUCCESS,
    payload: {
      documentIds,
      companyId,
    },
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "full_document.toaster.deactivate.title",
      bodyKey: "full_document.toaster.deactivate.body",
    },
  } as const)
export const deactivateFullDocumentFailure = (showToast: boolean = true) =>
  ({
    type: FullDocumentsActionsEnum.DEACTIVATE_FULL_DOCUMENT_FAILURE,
    withToast: showToast,
    toasterType: "error",
    message: {
      titleKey: "company-drop-document.deactivate.error.title",
      bodyKey: "company-drop-document.deactivate.error.body",
    },
  } as const)
export const deactivatedDocumentsAttempt = () =>
  ({
    type: FullDocumentsActionsEnum.GET_DEACTIVATED_DOCUMENTS_ATTEMPT,
  } as const)
export const deactivatedDocumentsSuccess = (payload: {
  deactivatedData: DeactivatedDocuments
  id: number
}) =>
  ({
    type: FullDocumentsActionsEnum.GET_DEACTIVATED_DOCUMENTS_SUCCESS,
    payload,
  } as const)
export const deactivatedDocumentsFailure = () =>
  ({
    type: FullDocumentsActionsEnum.GET_DEACTIVATED_DOCUMENTS_FAILURE,
  } as const)

export const getFullDocumentOfWritingAttempt = () =>
  ({
    type: FullDocumentsActionsEnum.GET_FULL_DOCUMENTS_OF_WRITING_ATTEMPT,
  } as const)
export const getFullDocumentOfWritingSuccess = (payload: {
  fullDocuments: FullDocumentAPI[]
  writingArchiveId: number
  fiscalYearId: number
}) =>
  ({
    type: FullDocumentsActionsEnum.GET_FULL_DOCUMENTS_OF_WRITING_SUCCESS,
    payload,
  } as const)
export const getFullDocumentOfWritingFailure = (error: AxiosError) =>
  ({
    type: FullDocumentsActionsEnum.GET_FULL_DOCUMENTS_OF_WRITING_FAILURE,
  } as const)
export const getFullDocumentOfBatchAttempt = () =>
  ({
    type: FullDocumentsActionsEnum.GET_FULL_DOCUMENTS_OF_BATCH_ATTEMPT,
  } as const)
export const getFullDocumentOfBatchSuccess = (
  fullDocuments: FullDocumentAPI[]
) =>
  ({
    type: FullDocumentsActionsEnum.GET_FULL_DOCUMENTS_OF_BATCH_SUCCESS,
    fullDocuments,
  } as const)
export const getFullDocumentOfBatchFailure = (error: AxiosError) =>
  ({
    type: FullDocumentsActionsEnum.GET_FULL_DOCUMENTS_OF_BATCH_FAILURE,
  } as const)

export const getPendingFullDocumentsAttempt = () =>
  ({
    type: FullDocumentsActionsEnum.GET_PENDING_FULL_DOCUMENTS_ATTEMPT,
  } as const)
export const getPendingFullDocumentsSuccess = (
  payload: FullDocumentAPI[],
  fiscalYearId: number
) =>
  ({
    type: FullDocumentsActionsEnum.GET_PENDING_FULL_DOCUMENTS_SUCCESS,
    payload,
    fiscalYearId,
  } as const)
export const getPendingFullDocumentsFailure = (error: AxiosError) =>
  ({
    type: FullDocumentsActionsEnum.GET_PENDING_FULL_DOCUMENTS_FAILURE,
    error,
  } as const)
export const renameUserFullDocumentNameAttempt = () =>
  ({
    type: FullDocumentsActionsEnum.RENAME_USER_FULL_DOCUMENTS_ATTEMPT,
  } as const)
export const renameUserFullDocumentNameFailure = () =>
  ({
    type: FullDocumentsActionsEnum.RENAME_USER_FULL_DOCUMENTS_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "company-full-document.rename.error.title",
      bodyKey: "company-full-document.rename.error.body",
    },
  } as const)
export const renameUserFullDocumentNameSuccess = () =>
  ({
    type: FullDocumentsActionsEnum.RENAME_USER_FULL_DOCUMENTS_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "company-full-document.rename.success.title",
      bodyKey: "company-full-document.rename.success.body",
    },
  } as const)

export const getDeactivatedReasonsAttempt = () =>
  ({
    type: FullDocumentsActionsEnum.GET_DEACTIVATED_REASONS_ATTEMPT,
  } as const)

export const getDeactivatedReasonsSuccess = (
  payload: DeactivatedReasonsResource
) =>
  ({
    type: FullDocumentsActionsEnum.GET_DEACTIVATED_REASONS_SUCCESS,
    payload,
  } as const)

export const getDeactivatedReasonsFailure = () =>
  ({
    type: FullDocumentsActionsEnum.GET_DEACTIVATED_REASONS_FAILURE,
  } as const)

export const getDocumentsFromFullDocumentAttempt = () =>
  ({
    type: FullDocumentsActionsEnum.GET_DOCUMENTS_FROM_FULL_DOCUMENT_ATTEMPT,
  } as const)

export const getDocumentsFromFullDocumentSuccess = (payload: DocumentFD) =>
  ({
    type: FullDocumentsActionsEnum.GET_DOCUMENTS_FROM_FULL_DOCUMENT_SUCCESS,
    payload,
  } as const)
export const getDocumentsFromFullDocumentFailure = () =>
  ({
    type: FullDocumentsActionsEnum.GET_DOCUMENTS_FROM_FULL_DOCUMENT_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "input-mask.get-documents-from-full-document.error.title",
      bodyKey: "input-mask.get-documents-from-full-document.error.body",
    },
  } as const)

export const getUrlFullDocumentAttempt = () =>
  ({
    type: FullDocumentsActionsEnum.GET_URL_FULL_DOCUMENT_ATTEMPT,
  } as const)
export const getUrlFullDocumentSuccess = () =>
  ({
    type: FullDocumentsActionsEnum.GET_URL_FULL_DOCUMENT_SUCCESS,
  } as const)
export const getUrlFullDocumentFailure = () =>
  ({
    type: FullDocumentsActionsEnum.GET_URL_FULL_DOCUMENT_FAILURE,
  } as const)

export const GetDocumentsToAttachToTransactionsAttempt = () =>
  ({
    type: FullDocumentsActionsEnum.GET_DOCUMENTS_TO_ATTACH_TO_TRANSACTIONS_ATTEMPT,
  } as const)
export const GetDocumentsToAttachToTransactionsSuccess = (
  payload: ResponseGetDocumentsToAttach
) =>
  ({
    type: FullDocumentsActionsEnum.GET_DOCUMENTS_TO_ATTACH_TO_TRANSACTIONS_SUCCESS,
    payload,
  } as const)
export const GetDocumentsToAttachToTransactionsFailure = () =>
  ({
    type: FullDocumentsActionsEnum.GET_DOCUMENTS_TO_ATTACH_TO_TRANSACTIONS_FAILURE,
  } as const)
export const GetDocumentsToAttachToTransactionsReset = () =>
  ({
    type: FullDocumentsActionsEnum.GET_DOCUMENTS_TO_ATTACH_TO_TRANSACTIONS_RESET,
  } as const)

export const AttachFullDocumentToTransactionAttempt = () =>
  ({
    type: FullDocumentsActionsEnum.ATTACH_FULL_DOCUMENT_TO_TRANSACTION_ATTEMPT,
  } as const)
export const AttachFullDocumentToTransactionSuccess = () =>
  ({
    type: FullDocumentsActionsEnum.ATTACH_FULL_DOCUMENT_TO_TRANSACTION_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "bank-management.transaction.attach-modal.success-title",
      bodyKey: "bank-management.transaction.attach-modal.success-message",
    },
  } as const)
export const AttachFullDocumentToTransactionFailure = () =>
  ({
    type: FullDocumentsActionsEnum.ATTACH_FULL_DOCUMENT_TO_TRANSACTION_FAILURE,
    toasterType: "error",
    message: {
      titleKey: "bank-management.transaction.attach-modal.error-title",
      bodyKey: "bank-management.transaction.attach-modal.error-message",
    },
  } as const)
export const AttachFullDocumentToTransactionReset = () =>
  ({
    type: FullDocumentsActionsEnum.ATTACH_FULL_DOCUMENT_TO_TRANSACTION_RESET,
  } as const)

export const DetachFullDocumentOfTransactionAttempt = () =>
  ({
    type: FullDocumentsActionsEnum.DETACH_FULL_DOCUMENT_OF_TRANSACTION_ATTEMPT,
  } as const)
export const DetachFullDocumentOfTransactionSuccess = (
  accountId: number,
  transactionId: number
) =>
  ({
    type: FullDocumentsActionsEnum.DETACH_FULL_DOCUMENT_OF_TRANSACTION_SUCCESS,
    accountId,
    transactionId,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey:
        "bank-management.transaction.attach-modal.full-doc.detach.title",
      bodyKey:
        "bank-management.transaction.attach-modal.full-doc.detach.message",
    },
  } as const)
export const DetachFullDocumentOfTransactionFailure = () =>
  ({
    type: FullDocumentsActionsEnum.DETACH_FULL_DOCUMENT_OF_TRANSACTION_FAILURE,
  } as const)

export const getMerchantFullDocumentsArchiveReset = () =>
  ({
    type: FullDocumentsActionsEnum.GET_MERCHANT_FULL_DOCUMENTS_ARCHIVE_RESET,
  } as const)
export const getMerchantFullDocumentsArchiveAttempt = () =>
  ({
    type: FullDocumentsActionsEnum.GET_MERCHANT_FULL_DOCUMENTS_ARCHIVE_ATTEMPT,
  } as const)
export const getMerchantFullDocumentsArchiveSuccess = () =>
  ({
    type: FullDocumentsActionsEnum.GET_MERCHANT_FULL_DOCUMENTS_ARCHIVE_SUCCESS,
  } as const)
export const getMerchantFullDocumentsArchiveFailure = () =>
  ({
    type: FullDocumentsActionsEnum.GET_MERCHANT_FULL_DOCUMENTS_ARCHIVE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `ged.download-archive.error.title`,
      bodyKey: `ged.download-archive.error.body`,
    },
  } as const)

export type FullDocumentsActionsType = ReturnType<
  | typeof fullDocumentUpdateAttempt
  | typeof fullDocumentUpdateSuccess
  | typeof deactivateFullDocumentAttempt
  | typeof deactivateFullDocumentSuccess
  | typeof deactivateFullDocumentFailure
  | typeof deactivateFullDocumentReset
  | typeof deactivatedDocumentsAttempt
  | typeof deactivatedDocumentsSuccess
  | typeof deactivatedDocumentsFailure
  | typeof getFullDocumentOfWritingAttempt
  | typeof getFullDocumentOfWritingSuccess
  | typeof getFullDocumentOfWritingFailure
  | typeof getFullDocumentOfBatchAttempt
  | typeof getFullDocumentOfBatchSuccess
  | typeof getFullDocumentOfBatchFailure
  | typeof getPendingFullDocumentsAttempt
  | typeof getPendingFullDocumentsSuccess
  | typeof getPendingFullDocumentsFailure
  | typeof getDeactivatedReasonsAttempt
  | typeof getDeactivatedReasonsFailure
  | typeof getDeactivatedReasonsSuccess
  | typeof getDocumentsFromFullDocumentAttempt
  | typeof getDocumentsFromFullDocumentFailure
  | typeof getDocumentsFromFullDocumentSuccess
  | typeof getUrlFullDocumentAttempt
  | typeof getUrlFullDocumentSuccess
  | typeof getUrlFullDocumentFailure
  | typeof GetDocumentsToAttachToTransactionsAttempt
  | typeof GetDocumentsToAttachToTransactionsSuccess
  | typeof GetDocumentsToAttachToTransactionsFailure
  | typeof GetDocumentsToAttachToTransactionsReset
  | typeof AttachFullDocumentToTransactionAttempt
  | typeof AttachFullDocumentToTransactionSuccess
  | typeof AttachFullDocumentToTransactionFailure
  | typeof AttachFullDocumentToTransactionReset
  | typeof DetachFullDocumentOfTransactionAttempt
  | typeof DetachFullDocumentOfTransactionSuccess
  | typeof DetachFullDocumentOfTransactionFailure
  | typeof getMerchantFullDocumentsArchiveReset
  | typeof getMerchantFullDocumentsArchiveAttempt
  | typeof getMerchantFullDocumentsArchiveSuccess
  | typeof getMerchantFullDocumentsArchiveFailure
>

export interface FullDocumentsState {
  fullDocumentsDeactivateStatus: "loading" | "success" | "error" | "idle"
  getMerchantFullDocumentsArchiveStatus:
    | "loading"
    | "success"
    | "error"
    | "idle"
}
export const fullDocumentsInitialState: FullDocumentsState = {
  fullDocumentsDeactivateStatus: "idle",
  getMerchantFullDocumentsArchiveStatus: "idle",
}

export function fullDocumentsReducer(
  state = fullDocumentsInitialState,
  action: FullDocumentsActionsType
): FullDocumentsState {
  switch (action.type) {
    case FullDocumentsActionsEnum.DEACTIVATE_FULL_DOCUMENT_RESET: {
      return { ...state, fullDocumentsDeactivateStatus: "idle" }
    }
    case FullDocumentsActionsEnum.DEACTIVATE_FULL_DOCUMENT_ATTEMPT: {
      return { ...state, fullDocumentsDeactivateStatus: "loading" }
    }
    case FullDocumentsActionsEnum.DEACTIVATE_FULL_DOCUMENT_FAILURE: {
      return { ...state, fullDocumentsDeactivateStatus: "error" }
    }
    case FullDocumentsActionsEnum.DEACTIVATE_FULL_DOCUMENT_SUCCESS: {
      return { ...state, fullDocumentsDeactivateStatus: "success" }
    }
    case FullDocumentsActionsEnum.GET_MERCHANT_FULL_DOCUMENTS_ARCHIVE_RESET: {
      return { ...state, getMerchantFullDocumentsArchiveStatus: "idle" }
    }
    case FullDocumentsActionsEnum.GET_MERCHANT_FULL_DOCUMENTS_ARCHIVE_ATTEMPT: {
      return { ...state, getMerchantFullDocumentsArchiveStatus: "loading" }
    }
    case FullDocumentsActionsEnum.GET_MERCHANT_FULL_DOCUMENTS_ARCHIVE_FAILURE: {
      return { ...state, getMerchantFullDocumentsArchiveStatus: "error" }
    }
    case FullDocumentsActionsEnum.GET_MERCHANT_FULL_DOCUMENTS_ARCHIVE_SUCCESS: {
      return { ...state, getMerchantFullDocumentsArchiveStatus: "success" }
    }
    default:
      return { ...state }
  }
}

export type Direction = "debit" | "credit"
export type GoodsOrServices =
  | "goods"
  | "services"
  | "on_site_consumption"
  | null
export type DocumentSource = "eu" | "world" | "fr" | null

export interface FullDocumentAPI {
  id: number
  batch_document_id: number
  writing_archive_id: number
  buy_or_sell: buyOrSell
  document_reference: string
  user_file_name: string
  first_page_number?: number
  last_page_number?: number
  merchant_name?: string
  document_date?: string
  status?: string
  deactivated_because?: string
  archive_downloaded_at?: string
  archive_generated_at?: string
}

export interface FullDocument {
  deactivated_type: string
  archive_id: number
  created_at: Date
  deactivated_because: string
  id: number
  original_file_name: string
  user_file_name: string | null
  user_full_document_name: string | null
}

export interface DocumentImageInfos {
  signed_url: string
  page_number: number
}

export interface DocumentFD {
  documents: DocumentImageInfos[]
  full_document_id: number
}

export interface PendingFullDocumentDeactivated {
  fd_id: number
  buy_or_sell: buyOrSell | null | "bank"
  deactivated_because: string
  batch_document_id: number
  document_type: string | null
  user_batch_name: string | null
  original_batch_name: string
  fd_created_at: string
  documents_url?: string[]
}

export interface PendingFullDocumentProcessed {
  fd_id: number
  buy_or_sell: buyOrSell
  account_details: string
  account_number: string
  account_id: number
  amount: string
  currency_code: CurrencyCode
  direction: Direction
  journal_code: string
  vat_rate: string
  fd_document_date: string
  fd_user_file_name: string | null
  fd_document_due_date: string | null
  fd_document_reference: string | null
  merchant_code: string
  merchant_name: string
  merchant_id: number
  original_batch_name: string
}

export interface PendingFullDocumentsWithLinesResource {
  deactivated: PendingFullDocumentDeactivated[]
  processed: PendingFullDocumentProcessed[]
}

export type DeactivatedOfCompanyResponse = [
  ...DeactivatedDocumentsByFiscalYear[],
  TranslationsAndReasons
]

const isDeactivatedDocumentsByFiscalYear = (
  objectOfResponse: DeactivatedDocumentsByFiscalYear | TranslationsAndReasons
): objectOfResponse is DeactivatedDocumentsByFiscalYear => {
  return (
    (objectOfResponse as DeactivatedDocumentsByFiscalYear).fiscal_year_id !==
    undefined
  )
}

export interface DeactivatedDocuments {
  deactivatedDocumentsByFiscalYear: DeactivatedDocumentsByFiscalYear[]
  translationsAndReasons: TranslationsAndReasons
  urlDeactivatedPreview?: { fullDocId: number; url: string } | null
}

export interface DeactivatedDocumentsByFiscalYear {
  fiscal_year_id: number
  full_documents: FullDocument[]
}

export interface TranslationsAndReasons {
  user_deactivated_reasons: {}
}

export const deactivateFullDocumentThunk =
  ({
    companyId,
    documentIdList,
    buyOrSell,
  }: {
    companyId: number
    documentIdList: number[]
    buyOrSell: "buy" | "sell" | undefined | null
  }) =>
  (
    dispatch: Dispatch<AnyAction | RNBThunkAction>,
    getState: () => RootState
  ) => {
    dispatch(deactivateFullDocumentAttempt())
    axios
      .put<{ status: "ok" }>(
        `/companies/${companyId}/full_documents_user_deactivate`,
        documentIdList
      )
      .then(() => {
        dispatch(
          deactivateFullDocumentSuccess({
            documentIds: documentIdList,
            companyId,
          })
        )
        dispatch(getDeactivatedDocumentsThunk(companyId))
        const fiscalYearId = getState().fiscalYears.selectedFiscalYearId
        if (fiscalYearId && buyOrSell) {
          dispatch(GetFiscalYearMerchantsThunk({ fiscalYearId, buyOrSell }))
        }
      })
      .catch(() => {
        dispatch(deactivateFullDocumentFailure())
      })
  }

export const fullDocumentUpdateThunk =
  (documentId: number, companyId: number, deactivatedBecause: string) =>
  (
    dispatch: Dispatch<AnyAction | RNBThunkAction>,
    getState: () => RootState
  ) => {
    dispatch(fullDocumentUpdateAttempt())
    return axios
      .put<FullDocumentsActionsType>(
        `/full_documents/${documentId}/requalify_deactivated_because`,
        {
          deactivated_because: deactivatedBecause,
        }
      )
      .then(() => {
        getState().companies.companies[companyId].deactivatedDocuments =
          undefined

        dispatch(getDeactivatedDocumentsThunk(companyId))

        dispatch(fullDocumentUpdateSuccess({ documentId, deactivatedBecause }))
      })
      .catch(() => {
        dispatch(fullDocumentUpdateFailure())
      })
  }

export const getDeactivatedDocumentsThunk =
  (selectedCompanyId: number) =>
  (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    dispatch(deactivatedDocumentsAttempt())
    const fiduciary = getState().fiduciary
    return axios
      .get<DeactivatedOfCompanyResponse>(
        `/fiduciaries/${fiduciary.id}/companies/${selectedCompanyId}/deactivated_of_company`
      )
      .then(({ data }) => {
        const deactivatedDocumentsByFiscalYear = data.reduce((acc, current) => {
          if (!isDeactivatedDocumentsByFiscalYear(current)) {
            return acc
          }
          const deactivatedFullDocuments = current.full_documents.map(
            (fullDoc) => ({ ...fullDoc })
          )
          return [
            ...acc,
            {
              fiscal_year_id: current.fiscal_year_id,
              full_documents: deactivatedFullDocuments,
            },
          ]
        }, [] as DeactivatedDocumentsByFiscalYear[])
        const translationsAndReasons = data.pop() as TranslationsAndReasons

        return dispatch(
          deactivatedDocumentsSuccess({
            id: selectedCompanyId,
            deactivatedData: {
              deactivatedDocumentsByFiscalYear,
              translationsAndReasons,
            },
          })
        )
      })
      .catch(() => {
        dispatch(deactivatedDocumentsFailure())
      })
  }

export const renameUserFullDocumentNameThunk =
  (fullDocumentId: number, newName: string, companyId: number) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(renameUserFullDocumentNameAttempt())
    axios
      .put<{ status: "ok" }>(`full_documents/${fullDocumentId}`, {
        user_full_document_name: newName,
      })
      .then(() => {
        dispatch(getDeactivatedDocumentsThunk(companyId))
        dispatch(renameUserFullDocumentNameSuccess())
        dispatch(updateFullDocumentNameSuccess())
      })
      .catch(() => dispatch(renameUserFullDocumentNameFailure()))
  }

export const getByBatchThunk =
  (batchId: number) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(getFullDocumentOfBatchAttempt())

    return axios
      .get<FullDocumentAPI[]>(`/full_documents/get_by_batch`, {
        params: { batch_id: batchId },
      })
      .then(({ data }) => dispatch(getFullDocumentOfBatchSuccess(data)))
      .catch((error) => dispatch(getFullDocumentOfBatchFailure(error)))
  }

export const getByWritingThunk =
  ({
    writingArchiveId,
    companyId,
    fiscalYearId,
  }: {
    writingArchiveId: number
    companyId: number
    fiscalYearId: number
  }) =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch(getFullDocumentOfWritingAttempt())

    return axios
      .get<FullDocumentAPI[]>(`/full_documents/get_by_archive`, {
        params: {
          company_id: companyId,
          writing_archive_id: writingArchiveId,
        },
      })
      .then(({ data }) => {
        dispatch(
          getFullDocumentOfWritingSuccess({
            writingArchiveId,
            fullDocuments: data,
            fiscalYearId,
          })
        )
      })
      .catch((error: AxiosError) => {
        getFullDocumentOfWritingFailure(error)
      })
  }

export const getPendingFullDocumentsThunk =
  ({ companyId, fiscalYearId }: { companyId: number; fiscalYearId: number }) =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch(getPendingFullDocumentsAttempt())

    return axios
      .get<FullDocumentAPI[]>(`/full_documents/get_pending`, {
        params: {
          company_id: companyId,
        },
      })
      .then(({ data }) => {
        dispatch(getPendingFullDocumentsSuccess(data, fiscalYearId))
      })
      .catch((error: AxiosError) => {
        getPendingFullDocumentsFailure(error)
      })
  }

export const getDeactivatedReasonsThunk =
  () => (dispatch: Dispatch<AnyAction>) => {
    dispatch(getDeactivatedReasonsAttempt())
    return axios
      .get<DeactivatedReasonsResource>(
        "/full_documents/get_all_user_deactivated_reasons"
      )
      .then(({ data }) => {
        dispatch(getDeactivatedReasonsSuccess(data))
      })
      .catch(() => {
        dispatch(getDeactivatedReasonsFailure())
      })
  }

export interface DeactivatedReasonsResource {
  deactivated_reasons: {}
  user_deactivated_reasons: DeactivatedReasonType
}

export const getDocumentsFromFullDocumentThunk =
  (fullDocumentId: number) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(getDocumentsFromFullDocumentAttempt())

    return axios
      .get<DocumentFD>(`/full_documents/get_documents_from_full_document`, {
        params: {
          full_document_id: fullDocumentId,
        },
      })
      .then(({ data }) => {
        dispatch(getDocumentsFromFullDocumentSuccess(data))
      })
      .catch(() => {
        dispatch(getDocumentsFromFullDocumentFailure())
      })
  }

export const getUrlFullDocumentThunk =
  (fullDocumentId: number, fiscalYearId: number) =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch(getUrlFullDocumentAttempt())

    return axios
      .get<string[]>(`/full_documents/get_url_document_complete_full_doc`, {
        params: {
          id: fullDocumentId,
        },
      })
      .then(({ data }) => {
        dispatch(getUrlFullDocumentSuccess())
        dispatch(saveDocumentUrl(data, fullDocumentId, fiscalYearId))
      })
      .catch(() => {})
  }

export const getUrlForDeactivatedThunk =
  (fullDocumentId: number, companyId: number) =>
  (dispatch: Dispatch<AnyAction>) => {
    axios
      .get<string>("/full_documents/get_url_for_full_document", {
        params: { id: fullDocumentId },
      })
      .then((response) => {
        dispatch(
          GetDeactivatedUrlPreview(response.data, companyId, fullDocumentId)
        )
      })
      .catch()
  }

export const getMerchantFullDocumentsArchive =
  ({
    idsFullDocToDdl,
    zipFileName,
  }: {
    idsFullDocToDdl: number[]
    zipFileName: string
  }) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(getMerchantFullDocumentsArchiveAttempt())
    axios
      .post<Blob>(
        `/full_documents/get_merchant_full_documents_archive`,
        { full_documents_ids: idsFullDocToDdl },
        { responseType: "blob" }
      )
      .then((blob) => {
        const url = window.URL.createObjectURL(blob.data)
        const a = document.createElement("a")
        a.href = url
        a.download = zipFileName
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
        dispatch(getMerchantFullDocumentsArchiveSuccess())
      })
      .catch(() => {
        dispatch(getMerchantFullDocumentsArchiveFailure())
      })
  }

export interface FullDocumentToAttach {
  id: number
  user_file_name: string
  original_file_name: string
  merchant_name: string
  date: string
  amount: number
  document_type: "invoice" | "credit_note" | "bill_of_exchange" | null
  buy_or_sell: "buy" | "sell" | null
  documents: DocumentInputMask[]
  bankin_transaction_id: number | null
  status: "assigned" | "deactivated"
  transactions_attached: TransactionAttached[]
}
interface TransactionAttached {
  id: number
  amount: number
  date: string
}

export interface BatchAttached {
  id: number
  created_at: string
  original_file_name: string
  email: string
}

export interface BatchDataAttached {
  current_batch: BatchAttached
  batch_original: BatchAttached | null
  has_archive_to_generate: boolean
}

interface ResponseGetDocumentsToAttach {
  full_documents: FullDocumentToAttach[]
  batch_data: BatchDataAttached | null
}

export const GetDocumentsToAttachToTransactionsThunk =
  (
    accountId: number,
    companyId: number,
    transaction_id: number,
    for_one_transaction: boolean,
    batch_to_attach?: boolean
  ) =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch(GetDocumentsToAttachToTransactionsAttempt())

    return axios
      .get<ResponseGetDocumentsToAttach>(
        `/bankin_accounts/${accountId}/get_documents_to_attach_to_transaction`,
        {
          params: {
            company_id: companyId,
            transaction_id: transaction_id,
            for_one_transaction: for_one_transaction,
            batch_to_attach: batch_to_attach || false,
          },
        }
      )
      .then(({ data }) => {
        dispatch(GetDocumentsToAttachToTransactionsSuccess(data))
      })
      .catch(() => {
        GetDocumentsToAttachToTransactionsFailure()
      })
  }

export const AttachFullDocumentToTransactionThunk =
  (bank_account_id: number, full_doc_id: number, transaction_id: number) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(AttachFullDocumentToTransactionAttempt())

    return axios
      .post(
        `/bankin_accounts/${bank_account_id}/attach_full_doc_to_transaction`,
        {
          full_doc_id: full_doc_id,
          transaction_id: transaction_id,
        }
      )
      .then(() => {
        dispatch(GetTransactionsThunk(bank_account_id))
        dispatch(AttachFullDocumentToTransactionSuccess())
      })
      .catch(() => {
        AttachFullDocumentToTransactionFailure()
      })
  }

export const DetachFullDocumentOfTransactionThunk =
  (bank_account_id: number, transaction_id: number) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(DetachFullDocumentOfTransactionAttempt())

    return axios
      .post(
        `/bankin_accounts/${bank_account_id}/detach_full_doc_of_transaction`,
        {
          transaction_id: transaction_id,
        }
      )
      .then(() => {
        dispatch(GetTransactionsThunk(bank_account_id))
        dispatch(
          DetachFullDocumentOfTransactionSuccess(
            bank_account_id,
            transaction_id
          )
        )
      })
      .catch(() => {
        DetachFullDocumentOfTransactionFailure()
      })
  }
