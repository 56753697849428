import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

import { buyOrSell, getIdFromParams } from "../../utils/company"
import { useRNBSelector } from "../../store/rootReducer"
import { getOutputDisplayThunk } from "../../store/ducks/merchants.ducks"
import { HeaderTitleWrapper } from "../dropDocuments/StyledDropsComponents"
import { TitleTable } from "../Commons/Table"
import { colors } from "../../styles/design.config"
import { Modal } from "../Commons/Modal"
import {
  getInputMaskIdentificationNumbersThunk,
  getMerchantModificationInfoThunk,
} from "../../store/ducks/merchantModification.ducks"
import { InvoicesReferences } from "./merchantModification/InvoicesReferences"
import { LegalEntitySelect } from "./merchantModification/LegalEntitySelect"
import { ControlBar } from "./merchantModification/ControlBar"
import { MerchantSelect } from "./merchantModification/MerchantSelect"
import { MerchantCodeSelect } from "./merchantModification/MerchantCodeSelect"
import { StandardLedgerOutput } from "../StandardLedgerOutput"
import { Text } from "../Commons/Text"
import { ReactComponent as Bulb } from "../../assets/bulb.svg"

interface MerchantModificationModalProps extends Ct.ModalComponentsProps {
  buyOrSell: buyOrSell
}

export const MerchantModificationModal = ({
  isDisplayed,
  onClose,
  buyOrSell,
}: MerchantModificationModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const { initialFullDocument, accountLine, editedLegalEntity, hasChanged } =
    useRNBSelector((state) => ({
      initialFullDocument: state.inputMask.initial_full_document,
      editedLegalEntity: state.merchantModification.editedMerchant.legalEntity,
      accountLine:
        state.merchants.merchants[
          state.merchantModification.editedMerchant.legalEntity?.merchant_id ||
            -1
        ]?.outputDisplay?.account_line,
      hasChanged:
        JSON.stringify(state.merchantModification.initialMerchant) !==
        JSON.stringify(state.merchantModification.editedMerchant),
    }))

  useEffect(() => {
    if (isDisplayed && initialFullDocument?.fd_id) {
      dispatch(
        getMerchantModificationInfoThunk({
          fullDocumentId: initialFullDocument.fd_id,
        })
      )
      dispatch(
        getInputMaskIdentificationNumbersThunk({
          fullDocumentId: initialFullDocument.fd_id,
        })
      )
      dispatch(
        getOutputDisplayThunk(
          selectedCompanyId,
          initialFullDocument.merchant_id,
          initialFullDocument.buy_or_sell
        )
      )
    }
  }, [isDisplayed, initialFullDocument?.fd_id])

  useEffect(() => {
    if (
      !initialFullDocument ||
      !editedLegalEntity ||
      !editedLegalEntity.merchant_id
    ) {
      return
    }

    dispatch(
      getOutputDisplayThunk(
        selectedCompanyId,
        editedLegalEntity.merchant_id,
        initialFullDocument.buy_or_sell
      )
    )
  }, [editedLegalEntity?.merchant_id])

  const closeAfterConfirm = () => {
    if (hasChanged) {
      const warningMessage = intl.formatMessage({
        id: `input-mask.merchant-modification.${buyOrSell}.exit-message-confirm`,
      })
      if (window.confirm(warningMessage)) {
        onClose()
      }
    } else {
      onClose()
    }
  }

  return (
    <Modal
      isDisplayed={isDisplayed}
      onClose={closeAfterConfirm}
      top={`5vh`}
      left={"5vw"}
    >
      <StyledCard>
        <Wrapper>
          <Ct.CloseCross onClick={closeAfterConfirm} />
          <Ct.Spacer />
          <Ct.Title
            text={intl.formatMessage({
              id: `input-mask.merchant-modification.${buyOrSell}.title`,
            })}
            size={3}
          />
          <Ct.Spacer />
          <Ct.Separator size="full" />
          <Ct.Spacer height={4} />
          <ContainerMargins>
            <Ct.Spacer width={4} />
            <TableWrapper>
              <StyledTableHeader>
                <StyledHeaderTitleWrapper calculatedWidth={28}>
                  <TitleTable
                    tid={`input-mask.merchant-modification.table.invoices.title`}
                    intl={intl}
                  />
                </StyledHeaderTitleWrapper>
                <StyledHeaderTitleWrapper calculatedWidth={28}>
                  <TitleTable
                    tid={`input-mask.merchant-modification.table.legal-entity.title`}
                    intl={intl}
                  />
                </StyledHeaderTitleWrapper>
                <StyledHeaderTitleWrapper calculatedWidth={28}>
                  <TitleTable
                    tid={`input-mask.merchant-modification.table.merchant.${buyOrSell}.title`}
                    intl={intl}
                  />
                </StyledHeaderTitleWrapper>
                <StyledHeaderTitleWrapper calculatedWidth={28}>
                  <TitleTable
                    tid={`input-mask.merchant-modification.table.merchant-code.${buyOrSell}.title`}
                    intl={intl}
                  />
                </StyledHeaderTitleWrapper>
              </StyledTableHeader>
              <StyledTableBody>
                <StyledFlex1>
                  <InvoicesReferences />
                </StyledFlex1>
                <StyledFlex1>
                  <LegalEntitySelect />
                </StyledFlex1>
                <StyledFlex1>
                  <MerchantSelect />
                </StyledFlex1>
                <StyledFlex1>
                  <MerchantCodeSelect />
                </StyledFlex1>
              </StyledTableBody>
            </TableWrapper>
            <Ct.Spacer width={4} />
          </ContainerMargins>

          <Ct.Spacer height={4} />

          {accountLine ? (
            <>
              <ContainerMargins>
                <Ct.Spacer width={4} />
                <Flex>
                  <Bulb />
                  <Ct.Spacer width={1} />
                  <Text
                    text={intl.formatMessage({
                      id: `input-mask.merchant-modification.${buyOrSell}.title`,
                    })}
                    textStyle={{
                      fontWeight: 600,
                      fontSize: 2,
                    }}
                  />
                </Flex>
                <Ct.Spacer width={4} />
              </ContainerMargins>
              <Ct.Spacer />
              <ContainerMargins>
                <Ct.Spacer width={4} />
                <StandardLedgerOutput accountLine={accountLine} />
                <Ct.Spacer width={4} />
              </ContainerMargins>
            </>
          ) : null}

          <Ct.Spacer height={4} />

          <ControlBar onClose={onClose} />
        </Wrapper>
      </StyledCard>
    </Modal>
  )
}

const StyledCard = styled(Ct.Card)`
  justify-content: space-between;
  padding: 0;
  overflow: hidden;
  width: 90vw;
  height: 90vh;
  overflow-y: auto;
`
const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`
const StyledTableHeader = styled(Ct.TableHeader)``
const StyledTableBody = styled.div`
  width: 100%;

  display: flex;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 1px 4px 11px 0 rgba(76, 119, 219, 0.24);
  border-radius: 10px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border: 1px solid #edeeef;
  border-top: none;
`

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const ContainerMargins = styled.div`
  display: flex;
  width: 100%;
`

const Flex = styled.div`
  display: flex;
  width: 100%;
`

const StyledFlex1 = styled.div`
  display: flex;
  flex: 1;
  min-width: 25rem;
  min-height: 15rem;
  padding: 0 1rem;
  border-right: 1px solid ${colors.mist};
  flex-direction: column;
  justify-content: space-evenly;
`

const StyledHeaderTitleWrapper = styled(HeaderTitleWrapper)`
  flex: 1;
  width: unset;
`
