import frFR from "date-fns/locale/fr"
import { format } from "date-fns"
import { DateTime } from "luxon"
import { createGlobalStyle } from "styled-components/macro"
import { DateRange, RangeType } from "rsuite/esm/DateRangePicker"
import { SyntheticEvent } from "react"

export const formatTimeFRstring = (dateString: string) => {
  if (!dateString) return ""
  return new Date(dateString).toLocaleTimeString("fr")
}
export const formatDateFRstring = (dateString: string) => {
  if (!dateString) return "Aucune"
  return new Date(dateString).toLocaleDateString("fr")
}

export const formatDateDate = (dateString: Date) => {
  return new Date(dateString).toLocaleDateString()
}

export const formatDateFRdate = (dateString: Date) => {
  if (!dateString) return "Aucune"
  return new Date(dateString).toLocaleDateString("fr")
}

export const dateToFullDocumentFormat = (date: Date | null | undefined) => {
  if (!date) return null
  return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd")
}

export function formatDateNumbering(
  numberingDateFormat: string,
  dateString: Date
): string {
  if (!numberingDateFormat) {
    return ""
  }
  const englishFormat = numberingDateFormat
    .replace(/A/g, "y")
    .replace(/J/g, "d")

  return format(dateString, englishFormat)
}

export const monthsArray = [
  { value: "1", label: "Janvier" },
  { value: "2", label: "Février" },
  { value: "3", label: "Mars" },
  { value: "4", label: "Avril" },
  { value: "5", label: "Mai" },
  { value: "6", label: "Juin" },
  { value: "7", label: "Juillet" },
  { value: "8", label: "Août" },
  { value: "9", label: "Septembre" },
  { value: "10", label: "Octobre" },
  { value: "11", label: "Novembre" },
  { value: "12", label: "Décembre" },
]

export function formatDateFrWithSeconds(dateString: string) {
  const date = new Date(dateString)
  const datePattern = new Intl.DateTimeFormat("fr", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  })
  return datePattern.format(date)
}

export function getDueDate({
  billDate,
  paymentPeriod,
}: {
  billDate: string
  paymentPeriod: number
}) {
  return DateTime.fromFormat(billDate, "yyyy-MM-dd")
    .plus({
      day: paymentPeriod,
    })
    .toFormat("yyyy-MM-dd")
}

export const optionsConfig = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
}

const Calendar = {
  sunday: "Di",
  monday: "Lu",
  tuesday: "Ma",
  wednesday: "Me",
  thursday: "Je",
  friday: "Ve",
  saturday: "Sa",
  ok: "Appliquer",
  today: "Aujourd'hui",
  yesterday: "Hier",
  hours: "Heures",
  minutes: "Minutes",
  seconds: "Secondes",
  formattedMonthPattern: "MMMM yyyy",
  formattedDayPattern: "dd MMMM yyyy",
  dateLocale: frFR,
}

export const locale = {
  Calendar,
  DatePicker: {
    ...Calendar,
  },
  DateRangePicker: {
    ...Calendar,
    last7Days: "7 derniers jours",
  },
}

export interface InputDateProps {
  value: [Date, Date] | null
  onChange: (
    value: DateRange | null,
    event: SyntheticEvent<Element, Event>
  ) => void
  onClose?: () => void
  minimum?: string
  maximum?: string
  placement?: Placement
  editable?: boolean
  cleanable?: boolean
  closable?: boolean
  predefinedRanges?: RangeType[]
  placeholder?: string
  hideBoxShadow?: boolean
  fontWeight?: string
  fontSize?: string
  fontFamily?: string
}

export type Placement =
  | "bottomStart"
  | "bottomEnd"
  | "topStart"
  | "topEnd"
  | "leftStart"
  | "leftEnd"
  | "rightStart"
  | "rightEnd"
  | "auto"
  | "autoVerticalStart"
  | "autoVerticalEnd"
  | "autoHorizontalStart"
  | "autoHorizontalEnd"

/* stylelint-disable no-descending-specificity */
export const StyledCalendarGlobal = createGlobalStyle`
  .rs-picker-error > .rs-picker-input-group {
    border-color: transparent!important;
    outline: none!important;
  }

  .rs-input-group:not(.rs-input-group-disabled):hover {
    border-color: transparent!important;
  }

  .rs-input-group:not(.rs-input-group-disabled):focus-within {
    border-color: transparent!important;
    outline: none!important;
  }

  .rs-picker-error>.rs-picker-input-group:focus-within{
    border-color: transparent!important;
    outline: none!important;
  }

  .rs-input-group:not(.rs-input-group-disabled) {
    border-color: transparent!important;
    outline: none!important;
  }

  .rs-input {
    padding-left: 2rem;
  }

  .rs-calendar > .rs-calendar-header > div.rs-calendar-header-month-toolbar {
    width: 100%;
  }

  .rs-input-group.rs-input-group-inside .rs-input-group-addon {
    padding: 0;
  }

  .rs-picker-popup.rs-picker-popup-daterange .rs-calendar:first-child {
    border-right: none;
  }

  .rs-picker-daterange-predefined {
    height: 328.8px;
  }

  .rs-picker-daterange-predefined > .rs-stack-item {
    width: 100%;
  }

  .rs-picker-daterange-predefined > .rs-stack-item > button {
    width: 100%;
  }

  .rs-picker-popup {
    z-index: 1000;
  }
`
