import { useIntl } from "react-intl"
import { useRNBSelector } from "../../../store/rootReducer"
import { Text } from "../../Commons/Text"
import { ReactComponent as Lock } from "../../../assets/lock.svg"
import styled from "styled-components/macro"
import { colors } from "../../../styles/design.config"
import { Spacer } from "ldlj"

export const InvoicesReferences = () => {
  const intl = useIntl()

  const { merchantModificationInfo } = useRNBSelector((state) => ({
    merchantModificationInfo:
      state.merchantModification.merchantModificationInfo,
  }))

  const numberOfDisplayedReference = 5
  return (
    <>
      <Spacer />
      <SpaceBetween>
        <div />
        <Text
          text={merchantModificationInfo?.current_full_document_reference || ""}
          textStyle={{
            fontFamily: "Poppins",
            fontWeight: 700,
          }}
        />
        <StyledLock />
      </SpaceBetween>
      <Spacer />
      {merchantModificationInfo &&
      merchantModificationInfo.impacted_documents_references.length > 1 ? (
        <>
          <Spacer />
          <FlexStart>
            <Text
              text={intl.formatMessage(
                {
                  id: `input-mask.merchant-modification.table.invoices.details`,
                },
                {
                  invoicesNumber:
                    merchantModificationInfo.impacted_documents_references
                      .length,
                }
              )}
              textStyle={{
                fontFamily: "Poppins",
                fontWeight: 400,
                fontStyle: "italic",
              }}
            />
            {merchantModificationInfo.impacted_documents_references
              .filter((_, i) => i < numberOfDisplayedReference)
              .map((reference) => (
                <li key={reference}>
                  <Text
                    text={reference}
                    textStyle={{
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontStyle: "italic",
                    }}
                  />
                </li>
              ))}
            {merchantModificationInfo.impacted_documents_references.length >
              numberOfDisplayedReference && (
              <li>
                <Text
                  text={"etc."}
                  textStyle={{
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontStyle: "italic",
                  }}
                />
              </li>
            )}
          </FlexStart>
          <Spacer />
        </>
      ) : merchantModificationInfo &&
        merchantModificationInfo.impacted_documents_references.length === 1 ? (
        <Text
          text={intl.formatMessage(
            {
              id: `input-mask.merchant-modification.table.invoices.detail`,
            },
            {
              invoiceReference:
                merchantModificationInfo.impacted_documents_references[0],
            }
          )}
          textStyle={{
            fontFamily: "Poppins",
            fontWeight: 400,
            fontStyle: "italic",
          }}
        />
      ) : null}
    </>
  )
}
const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
const FlexStart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`

const StyledLock = styled(Lock)`
  fill: ${colors.cornflower};
`
